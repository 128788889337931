









































import Vue from 'vue'
export default Vue.extend({
	name: 'BaseSlideable',
	props: ['value'],
	data: () => ({
		isScrollable: false,
		scrollbarAtLeft: true,
		scrollbarAtRight: false,
	}),
	mounted() {
		this.getScrollRef().addEventListener('scroll', this.onScroll)
	},
	watch: {
		value: {
			deep: true,
			immediate: true,
			handler() {
				this.$nextTick(() => {
					const scrollRef = this.getScrollRef()
					if (scrollRef) {
						this.isScrollable =
							scrollRef.scrollWidth > scrollRef.clientWidth
					}
				})
			},
		},
	},
	methods: {
		onScroll() {
			const el = this.getScrollRef()
			this.scrollbarAtLeft = el.scrollLeft - 50 <= 0
			this.scrollbarAtRight =
				el.clientWidth + el.scrollLeft >= el.scrollWidth
		},
		getScrollRef(): Element {
			return this.$refs.scrollRef as Element
		},
		onScrollRight() {
			const el = this.getScrollRef()
			el.scrollTo({
				behavior: 'smooth',
				left: el.scrollLeft + 400,
			})
		},
		onScrollLeft() {
			const el = this.getScrollRef()
			el.scrollTo({
				behavior: 'smooth',
				left: el.scrollLeft - 400,
			})
		},
	},
})

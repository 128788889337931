import Vue from 'vue'
import Vuex, { createLogger } from 'vuex'
import { IS_DEV } from '@/consts'
import { importModules } from 'vuelpers'
import { RootState, VuexModules } from '@/types'

import createPersistedState from 'vuex-persistedstate'

const modules = importModules<VuexModules>(
	require.context('./modules', true, /\.store\.(js|ts)$/)
)

const plugins = [
	createPersistedState({
		key: 'zx55',
		paths: [
			'global.config',
			'global.currentCurrency',
			'auth.superUser',
			'auth.accessToken',
			'auth.currentUser',
			'products.recentProductsIds',
			'carts.carts',
			'carts.currentOrder',
		],
	}),
]

if (IS_DEV) {
	plugins.push(createLogger())
}

Vue.use(Vuex)
const store = new Vuex.Store<RootState>({
	getters: {},
	modules,
	plugins,
})

export default store

import colors from 'vuetify/lib/util/colors'
import { OrderStatus } from './types'

export const SESSION_KEY = 'zx55'
export const IS_DEV = process.env.NODE_ENV === 'development'
export const SERVER_BASE_URL = process.env.VUE_APP_SERVER_BASE_URL || ''
// export const SERVER_BASE_URL = 'http://192.168.31.165:8000'
// export const SERVER_BASE_URL = 'http://127.0.0.1:8000'
// export const SERVER_BASE_URL = 'https://dev.zx55.com/backend/public'

export const TAX_RATE = 20 / 100

export enum CurrencyCodeSymbols {
	USD = '$',
	EUR = '€',
	GBP = '£',
}

export enum BrandQuality {
	'SKF' = 1,
	'FAG',
	'Premium',
	'Budget',
}

export const APP_META = {
	title: 'Stainless Steel Fittings & Pipe from zx55.com',
	titleTemplate: '%s - Zx55',
	meta: [
		{
			name: 'keywords',
			content:
				'Flanges, Stainless Steel Pipes, Butt-Weld Fittings, Flange Gaskets, High Pressure Fittings, Hygienic Fittings, BSP Low Pressure Fittings, Stainless Steel Valves',
		},
		{
			name: 'description',
			content:
				'Premier UK online supplier of Stainless Steel Pipes & Products, including: Flanges, Stainless Steel Pipes, Butt-Weld Fittings, Flange Gaskets, High Pressure Fittings, Hygienic Fittings, BSP Low Pressure Fittings, Stainless Steel Valves',
		},
		{
			property: 'og:title',
			content: 'Stainless Steel Fittings & Pipe from zx55.com',
		},
		{
			property: 'og:description',
			content:
				'Premier UK online supplier of Stainless Steel Pipes & Products, including: Flanges, Stainless Steel Pipes, Butt-Weld Fittings, Flange Gaskets, High Pressure Fittings, Hygienic Fittings, BSP Low Pressure Fittings, Stainless Steel Valves',
		},
		{
			property: 'og:url',
			content: 'https://dev.zx55.com/',
		},
		{
			property: 'og:image',
			content:
				'https://dev.zx55.com/backend/public/storage/configure/logo.a5f992a2.png',
		},
	],
}

export const StatusColor = {
	[OrderStatus.Approved]: colors.green.base,
	[OrderStatus.Acknowledged]: colors.teal.base,
	[OrderStatus.Cancelled]: colors.red.base,
	[OrderStatus.Downloaded]: colors.cyan.base,
	[OrderStatus.POPrinted]: colors.indigo.base,
	[OrderStatus.Pending]: colors.orange.base,
	[OrderStatus.Proforma]: colors.deepPurple.base,
	[OrderStatus.Shipped]: colors.blue.base,
}

import store from '@/store'
import { Currency, Config, CurrencyCode } from './../types'
import { CurrencyCodeSymbols } from '@/consts'

export const fallbackCurrency: Currency = {
	rate: 1,
	flag: 'gb',
	code: 'GBP',
	name: 'British Pound',
}

const getConfig = (): Config | undefined => {
	return store.getters['$config']
}

const currentCurrency = (): Currency | undefined => {
	return store.getters['$currentCurrency']
}

const currencies = () => {
	return getConfig()?.vExchangeCurrencyCodes || []
}

export const getCurrency = (
	currency?: string | Currency,
	fallback: Currency = fallbackCurrency
): Currency | undefined => {
	const currencyCode =
		typeof currency === 'string'
			? currency || fallback.code
			: (currency || fallback).code
	return currencies().find((c) => c.code === currencyCode)
}

export const getCurrencySign = (currency: CurrencyCode | Currency) => {
	const currencyCode = typeof currency === 'string' ? currency : currency.code
	return CurrencyCodeSymbols[currencyCode]
}

export const getExchangeRage = (config?: Config) => {
	const currency = getCurrency(currentCurrency(), fallbackCurrency)
	if (!currency) return 1

	const rate = currency.rate || 1
	if (currency.code === config?.vDefaultCurrencyCode) return rate
	return rate + +(config?.dPercentage || 0)
}

export const getBaseRate = (config?: Config): number => {
	const currency = getCurrency(config?.vDefaultCurrencyCode, fallbackCurrency)
	return currency?.rate || 1
}

export const getExchangedValue = (price = 0): number => {
	const config = getConfig()
	return (price * getExchangeRage(config)) / getBaseRate(config)
}

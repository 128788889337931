



























































































import Vue from 'vue'
import { User, VFormRef } from '@/types'
import { Accessors } from 'vue/types/options'
import { getRouteMeta } from 'vuelpers'
import { getDefaultRoute } from '@/helpers'
import { mapActions, mapGetters } from 'vuex'
import { getValidators } from '@/mixins/FormMixin'
import { APP_META } from '@/consts'
import { MetaInfo } from 'vue-meta'
export default Vue.extend({
	name: 'SignIn',
	metaInfo(): MetaInfo {
		return {
			...APP_META,
			title: `Login | ${APP_META.title}`,
		}
	},
	data: () => ({
		error: false,
		isLoading: false,
		form: {
			valid: false,
		},
		isVisiblePassword: false,
		credentials: {
			email: '',
			password: '',
		},
		rules: getValidators('email', 'required'),
	}),
	computed: {
		...(mapGetters('auth', ['$currentUser', '$isLoggedIn']) as Accessors<{
			$currentUser: User
			$isLoggedIn: boolean
		}>),
	},
	methods: {
		...mapActions('auth', ['login', 'setAuthState']),
		onInitSignUp() {
			this.setAuthState({
				'authentication.currentTab': 'signup',
			})
		},
		onInitForgotPassword() {
			this.setAuthState({
				'authentication.currentTab': 'forgotPassword',
			})
		},
		async onSubmit() {
			if (!(this.$refs.formRef as VFormRef).validate()) return

			this.isLoading = true

			if (this.$isLoggedIn) {
				await this.$store.dispatch('carts/resetState')
				await this.$store.dispatch('auth/logout')
			}
			const [err] = await this.login(this.credentials)

			this.isLoading = false

			if (err) return (this.error = !!err)

			// Ensure role
			const requireRoles = getRouteMeta(this.$route, 'requireRoles')
			if (requireRoles && !requireRoles.includes(this.$currentUser.vRole)) {
				this.$router.replace(getDefaultRoute(this.$currentUser.vRole))
			}
			// else this.$router.push('/')

			this.setAuthState({ 'authentication.dialog': false })
			this.error = !!err
		},
	},
})
























































































































































































































































































































































































import Vue from 'vue'

import { mapGetters } from 'vuex'
import { Pagination, encodeString, hasHistory } from 'vuelpers'
import { Accessors } from 'vue/types/options'
import { Cart, Category, Config, User } from '@/types'
import { slugify } from '@/helpers'

import DropdownCart from '../dropdowns/DropdownCart.vue'
import DropdownProfile from '../dropdowns/DropdownProfile.vue'
import GlobalSearch from '@/components/GlobalSearch.vue'
import CurrencyChanger from '../CurrencyChanger.vue'

type NavLink = {
	text: string
	to: string
	isMenu?: boolean
	items?: Category[]
}

export default Vue.extend({
	name: 'AppHeader',
	components: {
		DropdownCart,
		DropdownProfile,
		GlobalSearch,
		CurrencyChanger,
	},
	props: {
		hideCart: {
			default: false,
			type: Boolean as Vue.PropType<boolean>,
		},
	},
	data: () => ({
		drawer: false,
	}),
	computed: {
		...({
			...mapGetters('carts', ['$carts']),
			...mapGetters('category', ['$categories']),
			...mapGetters(['$config', '$logo', '$logoFallback']),
			...mapGetters('auth', [
				'$isLoggedIn',
				'$currentUser',
				'$isCustomer',
				'$isGuestCustomer',
			]),
		} as Accessors<{
			$logo: string
			$config: Config
			$currentUser: User
			$isLoggedIn: boolean
			$logoFallback: string
			$carts: Pagination<Cart>
			$isCustomer: boolean
			$isGuestCustomer: boolean
			$categories: Pagination<Category>
		}>),
		navLinks(): NavLink[] {
			return [
				// {
				// 	text: 'Product',
				// 	to: '/products',
				// },
				{
					// text: 'Vehicles',
					text: 'OEM',
					to: '/oem',
				},
				{
					text: 'Categories',
					to: '/categories',
					isMenu: true,
					items: this.$categories.data,
				},
				// {
				// 	text: 'Information',
				// 	to: '#',
				// },
			]
		},
		contactPhone(): string {
			const { vPhone, vPhoneCode } = this.$config || {}
			if (!vPhone || !vPhoneCode) return ''
			return `${vPhoneCode} ${vPhone}`
		},
		isInAdvancedSearch(): boolean {
			return this.$route.name === 'AdvancedSearch'
		},
	},
	methods: {
		slugify,
		encodeString,
		onClearAdvancedSearch() {
			this.$store.commit('SET', { 'filter.dialog': false })
			hasHistory() ? this.$router.back() : this.$router.push('/')
		},
		onOpenFilter() {
			this.$store.commit('SET', { 'filter.dialog': true })
		},
		onClickSignUp() {
			this.$store.commit('auth/SET', {
				authentication: {
					dialog: true,
					persisted: false,
					currentTab: 'signin',
				},
			})
		},
		onClickLogout() {
			this.$store.dispatch('auth/logout')
		},
	},
})

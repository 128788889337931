







































import Vue, { PropType } from 'vue'
import { VTextField, VTextarea } from 'vuetify/lib/components'

export default Vue.extend({
	name: 'BaseInput',
	components: {
		VTextarea,
		VTextField,
	},
	props: {
		tag: {
			default: 'v-text-field',
			type: String as PropType<'v-text-field' | 'v-textarea'>,
		},
		type: {
			type: String as PropType<
				'text' | 'email' | 'password' | 'number' | 'tel'
			>,
			validator: (value: string) => {
				return ['text', 'email', 'password', 'number', 'tel'].includes(
					value
				)
			},
		},
		large: {
			type: Boolean as PropType<boolean>,
			default: false,
		},
		hideDetails: {
			default: 'auto',
			type: [Boolean, String] as PropType<boolean | string>,
		},
		error: {
			default: false,
			type: Boolean as PropType<boolean>,
		},
		errorMessages: {
			default: () => [],
			type: Array as PropType<string[]>,
		},
		label: {
			type: String as PropType<string>,
		},
		vLabel: {
			type: String as PropType<string>,
		},
		largeLabel: {
			type: Boolean as PropType<boolean>,
			default: false,
		},
		clearable: {
			default: false,
			type: Boolean as PropType<boolean>,
		},
		mediumLabel: {
			type: Boolean as PropType<boolean>,
			default: false,
		},
		labelClass: {
			type: String as PropType<string>,
			default: '',
		},
		placeholder: {
			type: String as PropType<string>,
		},
		value: {
			default: '',
			type: [String, Number] as PropType<string | number>,
		},
		appendIcon: {
			default: undefined,
			type: String as PropType<string>,
		},
		rules: {
			type: Array as PropType<any[]>,
			default: () => [],
		},
	},
	data: () => ({
		id: Math.random().toString(36).substr(2, 9),
	}),
	computed: {
		vModel: {
			get(): string | number {
				return this.value
			},
			set(v: string | number) {
				this.$emit('input', v)
			},
		},
	},
})

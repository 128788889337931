import { SuppliersApi } from '@/api'
import { ActionTree } from 'vuex'
import { createPaginaion } from 'vuelpers'
import { SupplierState } from '@/types'

import {
	// MUTATION TYPES
	SET,
	UPDATE,
	DELETE,
	PUSH,
	createGetters,
	createMutations,
	handleAction,
} from 'vuelpers'

const initialState = (): SupplierState => ({
	suppliers: createPaginaion(),
	supplier: undefined,
})

const state = initialState()
const mutations = createMutations(SET, UPDATE, DELETE, PUSH)
const getters = createGetters<SupplierState>('manufacturer', 'suppliers')

const actions: ActionTree<SupplierState, unknown> = {
	getSuppliers({ commit }, payload: any) {
		return handleAction(SuppliersApi.show(payload), (res: any) => {
			commit(SET, { suppliers: res })
		})
	},
	getSupplierById({ commit }, id: number | string) {
		return handleAction(SuppliersApi.index(id), (res: any) => {
			commit(SET, { category: res })
		})
	},
	createSupplier({ commit }, data: any) {
		console.log(data)
		return handleAction(SuppliersApi.store(data), (res: any) => {
			commit(PUSH, ['suppliers.data', res])
		})
	},
	updateSupplier({ commit }, data: any) {
		return handleAction(
			SuppliersApi.update(data),
			(res: any) => {
				commit(UPDATE, ['suppliers.data', res, 'iSupplierListId'])
			},
			(er: any) => {
				console.log(er)
			}
		)
	},
	deleteSupplierById({ commit }, id: number | string) {
		return handleAction(SuppliersApi.delete(id), () => {
			commit(DELETE, ['suppliers.data', id, 'iSupplierListId'])
		})
	},
}

export default {
	state,
	getters,
	actions,
	mutations,
	namespaced: true,
}

































import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'

import SignIn from '@/views/auth/SignIn.vue'
import SignUp from '@/views/auth/SignUp.vue'
import ForgotPassword from '@/views/auth/ForgotPassword.vue'
import AuthWrapper from '@/components/wrappers/AuthWrapper.vue'
import ChangePasswordForm from '../../views/auth/ChangePasswordForm.vue'

export default Vue.extend({
	name: 'DialogAuthentication',
	components: {
		SignIn,
		SignUp,
		AuthWrapper,
		ForgotPassword,
		ChangePasswordForm,
	},
	computed: {
		...mapGetters('auth', ['$authentication']),
		vModel: {
			get(): boolean {
				return this.$authentication.dialog
			},
			set(v: boolean) {
				this.setAuthState({ 'authentication.dialog': v })
			},
		},
		currentTab: {
			get(): string {
				return this.$authentication.currentTab
			},
			set(v: string) {
				this.setAuthState({ 'authentication.currentTab': v })
			},
		},
	},
	methods: {
		...mapActions('auth', ['setAuthState']),
		onClickLogo() {
			if (this.$route.path !== '/') {
				this.$router.push('/')
			}
			setTimeout(() => {
				this.vModel = false
			}, 300)
		},
	},
})


import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { fetchProfile, getUserMetaData, syncAuthSession } from './UseAuth'

export default Vue.extend({
	name: 'AuthMixin',
	computed: {
		...mapGetters('auth', [
			'$isLoggedIn',
			'$currentUser',
			'$accessToken',
			'$tokenExpiresAt',
		]),
	},
	watch: {
		$currentUser: {
			deep: true,
			handler(currentUser) {
				if (!currentUser) return
				syncAuthSession({
					isLoggedIn: this.$isLoggedIn,
					accessToken: this.$accessToken,
					tokenExpiresAt: this.$tokenExpiresAt,
					currentUser: getUserMetaData(currentUser),
				})
			},
		},
	},
	created() {
		if (this.$isLoggedIn) fetchProfile()
	},
	methods: {
		...mapMutations('auth', ['SET']),
		...mapActions('auth', ['logout']),
	},
})

























import Vue from 'vue'
import { truncate } from 'lodash'
export default Vue.extend({
	name: 'BaseBreadcrumbs',
	watch: {
		$historyStack: {
			deep: true,
			immediate: true,
			handler() {
				//
			},
		},
	},
	methods: {
		truncate,
	},
})

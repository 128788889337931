import { omit } from 'lodash'
import { ActionTree } from 'vuex'
import { ReportsApi } from '@/api'
import { Report, Query, RootState } from '@/types'

import {
	// MUTATION TYPES
	SET,
	UNSHIFT,
	MERGE,
	UPDATE,
	DELETE,
	//
	Pagination,
	handleAction,
	createGetters,
	createMutations,
	createPaginaion,
} from 'vuelpers'

export interface ReportsState {
	reports: Pagination<Report>
}

const initialState = (): ReportsState => ({
	reports: createPaginaion(),
})

const state = initialState()
const getters = createGetters<ReportsState>('reports')
const mutations = createMutations(SET, UNSHIFT, UPDATE, MERGE, DELETE)

const actions: ActionTree<ReportsState, RootState> = {
	fetchReports({ commit, state: { reports } }, payload: Query) {
		if (!reports.isLoaded) commit(SET, { 'reports.isLoading': true })
		else commit(SET, { 'reports.isRefetching': true })

		return handleAction(ReportsApi.get(payload), (res: any) => {
			commit(SET, {
				reports: (v: typeof reports) => ({
					...v,
					...omit(res, 'data'),
					isLoaded: true,
					isLoading: false,
					isRefetching: false,
				}),
			})
			commit(MERGE, ['reports.data', res.data, 'iReportId', 'push'])
		})
	},
	resetState({ commit }) {
		commit(SET, initialState())
	},
}

const reportsModule = {
	state,
	getters,
	actions,
	mutations,
	namespaced: true,
}

export type ReportsModule = typeof reportsModule

export default reportsModule













































































































































import Vue from 'vue'
import { get, isFunction, isObject, pick } from 'lodash'
import { DataTableHeader } from 'vuetify'
import {
	changeLocationQuery,
	createPaginaion,
	omitEmpties,
	Pagination,
	queryString,
	secureDataType,
} from 'vuelpers'

type Action = {
	icon?: string
	text?: string
	event?: string
	divider?: boolean
}

type Query = {
	q: string
	page: number
	perPage: number
	vSearchText?: string
}

type PerPageItem = {
	text: string
	value: number | string
}

type FetchFunction = (query: Query) => Promise<unknown>

export default Vue.extend({
	name: 'BaseDataTable',
	props: {
		expanded: {
			default: () => [],
			type: Array as Vue.PropType<number[]>,
		},
		itemKey: {
			type: [String, Number] as Vue.PropType<string | number>,
		},
		autoExpandFirst: {
			default: false,
			type: Boolean as Vue.PropType<boolean>,
		},
		showExpand: {
			default: false,
			type: Boolean as Vue.PropType<boolean>,
		},
		singleExpand: {
			default: false,
			type: Boolean as Vue.PropType<boolean>,
		},
		translate: {
			type: Boolean as Vue.PropType<boolean>,
		},
		fetchFunction: {
			type: Function as Vue.PropType<FetchFunction>,
		},
		title: {
			default: 'Base Data Table',
			type: String as Vue.PropType<string>,
		},
		hideAdd: {
			default: false,
			type: Boolean as Vue.PropType<boolean>,
		},
		headers: {
			default: () => [],
			type: Array as Vue.PropType<DataTableHeader[]>,
		},
		provider: {
			default: () => createPaginaion(),
			type: Object as Vue.PropType<Pagination<any>>,
		},
		actions: {
			type: Array as Vue.PropType<Action[]>,
			default: () => [
				{ text: 'View details', event: 'view', icon: 'mdi-eye-outline' },
				{ text: 'Update', event: 'update', icon: 'mdi-pencil-outline' },
				{ divider: true },
				{ text: 'Delete', event: 'delete', icon: 'mdi-delete-outline' },
			],
		},
		perPageItems: {
			type: Array as Vue.PropType<PerPageItem[]>,
			default: () => [
				{ text: '10', value: '10' },
				{ text: '25', value: '25' },
				{ text: '50', value: '50' },
				{ text: '100', value: '100' },
			],
		},
	},
	data: () => ({
		ssKey: 'base-data-table-query',
		keyword: '',
		routePath: '',
		selectedAction: null,
		onDeleteId: null,
		perPage: { text: '10', value: '10' } as PerPageItem,
		fetchQuery: {
			q: '',
			page: 1,
			perPage: 10,
		} as Query,
	}),
	created() {
		this.routePath = this.$route.path

		const sessionQuery = JSON.parse(
			sessionStorage.getItem(this.ssKey) || '{}'
		)[this.routePath]

		let {
			q = sessionQuery?.q || sessionQuery?.vSearchText || '',
			page = sessionQuery?.page || this.provider.currentPage,
			perPage = sessionQuery?.perPage || this.provider.perPage.toString(),
		} = this.$route.query

		this.keyword = secureDataType(q) || ''
		this.perPage = this.getPerPageObject(perPage as string)

		this.fetchQuery = {
			q: this.keyword,
			perPage: this.perPageValue,
			page: +page || this.provider.currentPage,
		}

		this.fetchItems(this.fetchQuery).then(() => {
			if (this.autoExpandFirst && this.provider.data.length) {
				this.expandedModel = [this.provider.data[0]]
			}
		})
	},
	computed: {
		actionsOn(): any {
			return this.actions
				.filter((action) => action.event)
				.reduce((on: any, action, index) => {
					const name = action.event ?? `action:${index}`
					on[name] = (e: any) => this.$emit(name, e)
					return on
				}, {} as any)
		},
		perPageValue(): number {
			return +(isObject(this.perPage)
				? this.perPage.value === 'ALL'
					? this.provider.total
					: this.perPage.value
				: this.perPage)
		},
		cHeaders(): DataTableHeader[] {
			if (!this.translate) return this.headers
			return this.headers.map((header) => {
				return {
					...header,
					text: this.$t(header.text),
				}
			}) as DataTableHeader[]
		},
		currentPage: {
			get(): number {
				return this.provider.currentPage
			},
			set(v: number) {
				this.fetchItems({ ...this.fetchQuery, page: v })
			},
		},
		expandedModel: {
			get(): number[] {
				return this.expanded
			},
			set(v: number[]) {
				this.$emit('update:expanded', v)
			},
		},
	},
	methods: {
		get,
		isFunction,
		onClickClear() {
			this.keyword = ''
			this.fetchItems({ ...this.fetchQuery, vSearchText: '' })
		},
		onChangeLocationQuery(query: any) {
			changeLocationQuery(queryString.stringify(omitEmpties(query)))
		},
		onEnterKeyword() {
			this.fetchItems({
				...this.fetchQuery,
				vSearchText: this.keyword,
			})
		},
		getPerPageObject(v: string): PerPageItem {
			const perPageItem = this.perPageItems.find((ppi) => ppi.value == v)
			if (!perPageItem) return { text: v, value: v }
			return perPageItem
		},
		onChangePerPage() {
			this.fetchItems({
				...this.fetchQuery,
				perPage: +this.perPageValue,
			})
		},
		async fetchItems(query: Query) {
			if (!this.fetchFunction) return
			if (
				this.provider.isLoaded &&
				query.perPage >= this.provider.total &&
				query.page > 1
			) {
				query.page = 1
			}
			this.fetchQuery = omitEmpties(query)

			await this.fetchFunction(this.fetchQuery)
			this.onChangeLocationQuery(this.fetchQuery)
		},
	},
	beforeDestroy() {
		const query = JSON.parse(sessionStorage.getItem(this.ssKey) || '{}')
		query[this.routePath] = pick(this.fetchQuery, ['page', 'perPage'])
		sessionStorage.setItem(this.ssKey, JSON.stringify(query))
	},
})

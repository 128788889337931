






















































import Vue from 'vue'

import { Pagination } from 'vuelpers'
import { Cart, Category, Query, SortOrder, SuperUser } from '@/types'
import { mapGetters, mapActions } from 'vuex'
import { Accessors } from 'vue/types/options'

import DialogFilter from '@/components/dialogs/DialogFilter.vue'
import DropdownCart from '@/components/dropdowns/DropdownCart.vue'
import { baseCategorySelectWith, cartsQuery } from '@/queries'

export default Vue.extend({
	name: 'MainLayout',
	components: {
		DialogFilter,
		DropdownCart,
	},
	data: () => ({
		hideCart: false,
		links: ['Dashboard', 'Messages', 'Profile', 'Updates'],
	}),
	created() {
		// FETCH CATAGORIES
		if (!this.$categories.isLoaded && !this.$categories.isLoading) {
			this.$store.dispatch('category/getCategories', {
				page: 1,
				// perPage: 12,
				perPage: 100,
				orderByColumn: 'iListOrder',
				orderByValue: SortOrder.ASC,
				with: baseCategorySelectWith,
			} as Query)
		}
	},
	watch: {
		$isLoggedIn: {
			immediate: true,
			handler(val: boolean) {
				if (!val) return
				this.getCarts(cartsQuery)
			},
		},
	},
	computed: {
		...({
			...mapGetters('carts', ['$carts']),
			...mapGetters('category', ['$categories']),
			...mapGetters('auth', ['$superUser', '$isLoggedIn']),
		} as Accessors<{
			$isLoggedIn: boolean
			$superUser: SuperUser
			$carts: Pagination<Cart>
			$categories: Pagination<Category>
		}>),
	},
	methods: {
		...mapActions('carts', ['getCarts']),
		...mapActions('auth', ['exitViewAsCustomer']),
	},
})

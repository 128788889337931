











import Vue from 'vue'
export default Vue.extend({
	name: 'Flag',
	props: {
		width: {
			default: 24,
			type: Number as Vue.PropType<number>,
		},
		height: {
			default: 16,
			type: Number as Vue.PropType<number>,
		},
		code: {
			required: true,
			type: String as Vue.PropType<string>,
		},
	},
	data: () => ({
		baseUrl: 'https://flagcdn.com',
	}),
	computed: {
		mCode(): string {
			return this.code.toLowerCase()
		},
	},
})

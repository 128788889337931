var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('base-breadcrumbs'),_c('v-layout',[_c('div',{staticClass:"tw-flex-none",style:({ width: _vm.$vuetify.breakpoint.smAndDown ? 0 : ' 250px' })},[_c('v-navigation-drawer',_vm._b({staticClass:"drawer-border-none",model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},'v-navigation-drawer',
					_vm.$vuetify.breakpoint.smAndDown
						? {
								width: '80%',
								app: true,
								left: true,
								fixed: true,
								temporary: true,
						  }
						: {
								width: 250,
								permanent: true,
						  }
				,false),[_c('v-card',{staticClass:"tw-p-5 md:tw-pl-0 md:tw-pr-0 tw-pt-28 tw-pb-48",attrs:{"flat":""}},[_c('div',{staticClass:"tw-relative tw-w-max tw-group"},[_c('v-img',{ref:"profileImageRef",staticClass:"tw-rounded-lg tw-border-2 tw-border-solid tw-border-accent",attrs:{"width":"200","height":"200","src":_vm.$helper.imgSrc(_vm.$currentUser.vProfileImage, {
									type: 'users',
								})},on:{"error":function($event){return _vm.$helper.vImgErr({
									size: '200x200',
									ref: _vm.$refs.profileImageRef,
								})}}}),_c('v-btn',{staticClass:"tw-absolute tw-bottom-1 tw-right-1 tw-bg-white tw-opacity-0 tw-invisible tw-transition-all group-hover:tw-opacity-100 group-hover:tw-visible",attrs:{"small":"","icon":"","loading":_vm.isUploadingProfileImage},on:{"click":_vm.onClickUploadImage}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-camera")])],1)],1),_c('v-list',{staticClass:"tw-space-y-6 tw-mt-9",attrs:{"dense":""}},_vm._l((_vm.links),function(link){return _c('v-list-item',{key:link.label,staticClass:"tw-px-0",staticStyle:{"height":"auto !important"},on:{"click":function($event){link.action
									? link.action()
									: link.path &&
									  _vm.$route.path !== link.path &&
									  _vm.$router.push(link.path)}}},[_c('v-list-item-icon',{staticClass:"tw-m-0 tw-mr-4 tw-my-auto"},[_c('v-icon',{attrs:{"size":"28","color":"accent"}},[_vm._v(_vm._s(link.icon))])],1),_c('v-list-item-title',{staticClass:"tw-font-ave tw-text-2xl tw-font-medium tw-text-primary"},[_vm._v(" "+_vm._s(link.label)+" ")])],1)}),1)],1)],1)],1),_c('v-flex',{staticClass:"tw-ml-0 tw-px-3 lg:tw-px-0 md:tw-ml-5 lg:tw-ml-40 tw-min-h-screen lg:tw-min-h-0 tw-mt-0 md:tw-mt-24 lg:tw-mt-32",attrs:{"grow":""}},[(_vm.$vuetify.breakpoint.smAndDown)?_c('v-btn',{staticClass:"tw-mb-3 tw--ml-2",attrs:{"icon":""},on:{"click":function($event){_vm.drawer = true}}},[_c('v-icon',[_vm._v("mdi-menu")])],1):_vm._e(),_c('base-transition',{attrs:{"name":"slide-x"}},[_c('router-view')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import api, { AuthApi } from '../api'

import store from '../store'
import moment from 'moment'

import { User } from '@/types'
import { useRouter } from '../router'
import { SESSION_KEY } from '../consts'
import {
	jsonParse,
	handleAction,
	encodeString,
	decodeString,
	encodedCookies,
} from 'vuelpers'

export const getUserMetaData = (user: User) => ({
	iUserId: user.iUserId,
	vFirstName: user.vFirstName,
	vLastName: user.vLastName,
	vRole: user.vRole,
	email: user.email,
	vMobileNumber: user.vMobileNumber,
})

export const fetchProfile = (): Promise<any[]> => {
	return handleAction(
		AuthApi.currentUser.get(),
		(res: any) =>
			store.commit('auth/SET', {
				currentUser: res,
			}),
		(err: any) => {
			if (err.statusCode === 401) {
				onLoggedOut()
			}
		}
	) as Promise<any[]>
}

const handleLogin = (payload: any) => {
	return handleAction(AuthApi.login(payload))
}

const setAuthenticated = (payload: any) => {
	store.commit('auth/SET', payload)
	api.setHeaders({
		Authorization: `Bearer ${payload.accessToken}`,
	})
}

export const syncAuthState = () => {
	const cookie: any = encodedCookies.get(SESSION_KEY)
	if (!cookie || !cookie[SESSION_KEY]) {
		return onLoggedOut()
	}

	const [parseError, parsedCookie] = jsonParse(
		decodeString(cookie[SESSION_KEY])
	)

	if (parseError) return
	setAuthenticated(parsedCookie)
}

export const syncAuthSession = (payload: any) => {
	const expires = moment(Date.now()).diff(payload.tokenExpiresAt, 'days') || 1
	encodedCookies.set({
		expires,
		key: SESSION_KEY,
		value: encodeString(JSON.stringify(payload)),
	})
}

export const onLoggedIn = async (data: any) => {
	const currentUser = (data.user || {}) as User
	const authData = {
		isLoggedIn: true,
		accessToken: data.token,
		tokenExpiresAt: data.expiresAt,
	}
	setAuthenticated({ ...authData, currentUser })
	syncAuthSession({ ...authData, currentUser })
	return await fetchProfile()
}

export const onLoggedOut = async () => {
	encodedCookies.remove(SESSION_KEY)
	const modules: string[] = ['auth', 'orders']
	for (const module of modules) {
		await store.dispatch(`${module}/resetState`)
	}
	const router = useRouter()
	router.currentRoute.path !== '/' && (await router.push('/'))
	api.removeHeaders('Authorization')
}

export default {
	handleLogin,
	syncAuthState,
	syncAuthSession,
	onLoggedIn,
	setAuthenticated,
	onLoggedOut,
	fetchProfile,
}

import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
	breakpoint: {
		thresholds: {
			xs: 600, // 0px < 600px
			sm: 960, // 600px > < 960px
			md: 1264, // 960px > < 1264px*
			lg: 1704, // 1264px > < 1704px*
			// xl: > 1704px*
		},
		scrollBarWidth: 12,
	},
	theme: {
		themes: {
			light: {
				primary: '#0d1b2a',
				accent: '#EE914C',
			},
		},
	},
})

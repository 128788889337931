import { AdminsApi } from '@/api'
import { ActionTree } from 'vuex'
import { createPaginaion, UNSHIFT } from 'vuelpers'
// import { adminstate } from '@/types'

import {
	// MUTATION TYPES
	SET,
	UPDATE,
	DELETE,
	PUSH,
	createGetters,
	createMutations,
	handleAction,
} from 'vuelpers'

const initialState = (): any => ({
	admins: createPaginaion(),
	admin: undefined,
})

const state = initialState()
const getters = createGetters<any>('admins', 'admin')
const mutations = createMutations(SET, UPDATE, DELETE, UNSHIFT, PUSH)

const actions: ActionTree<any, unknown> = {
	getAdmins({ commit }, payload: any) {
		return handleAction(AdminsApi.show(payload), (res: any) => {
			commit(SET, { admins: res })
		})
	},
	getAdminById({ commit }, id: number | string) {
		return handleAction(AdminsApi.index(id), (res: any) => {
			commit(SET, { category: res })
		})
	},
	createAdmin({ commit }, data: any) {
		return handleAction(AdminsApi.store(data), (res: any) => {
			commit(UNSHIFT, ['admins.data', res])
		})
	},
	updateAdmin({ commit }, data: any) {
		return handleAction(
			AdminsApi.update(data),
			(res: any) => {
				commit(UPDATE, ['admins.data', res, 'iUserId'])
			},
			(er: any) => {
				console.log(er)
			}
		)
	},
	deleteAdminById({ commit }, id: number | string) {
		return handleAction(AdminsApi.delete(id), () => {
			commit(DELETE, ['admins.data', id, 'iUserId'])
		})
	},
}

export default {
	state,
	getters,
	actions,
	mutations,
	namespaced: true,
}

import { filtersDataApi } from '@/api'
import { ActionTree } from 'vuex'
import { createPaginaion, UNSHIFT } from 'vuelpers'
// import { any } from '@/types'

import {
	// MUTATION TYPES
	SET,
	UPDATE,
	DELETE,
	createGetters,
	createMutations,
	handleAction,
} from 'vuelpers'

const initialState = (): any => ({
	filterData: undefined,
	filtersData: createPaginaion(),
})

const state = initialState()
const mutations = createMutations(SET, UPDATE, DELETE, UNSHIFT)
const getters = createGetters<any>('filtersData', 'filterData')

const actions: ActionTree<any, unknown> = {
	getFilters({ commit }, payload: any) {
		return handleAction(filtersDataApi.index(payload), (res: any) => {
			commit(SET, { filtersData: res })
		})
	},
	getFilterById({ commit }, id: number | string) {
		return handleAction(filtersDataApi.show(id), (res: any) => {
			commit(SET, { filterData: res })
		})
	},
	createFilter({ commit }, data: any) {
		return handleAction(filtersDataApi.store(data), (res: any) => {
			commit(UNSHIFT, ['filtersData.data', res])
		})
	},
	updateFilter({ commit }, data: any) {
		return handleAction(
			filtersDataApi.update(data),
			(res: any) => {
				commit(UPDATE, ['filtersData.data', res, 'iFiltersDataId'])
			},
			(er: any) => {
				console.log(er)
			}
		)
	},
	deleteFilterById({ commit }, id: number | string) {
		return handleAction(filtersDataApi.delete(id), () => {
			commit(DELETE, ['filtersData.data', id, 'iFiltersDataId'])
		})
	},
}

export default {
	state,
	getters,
	actions,
	mutations,
	namespaced: true,
}

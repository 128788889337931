import { ActionTree } from 'vuex'
import { ConfigApi, ConfigursApi } from '@/api'
import { ConfigurationState } from '@/types'

import {
	// MUTATION TYPES
	SET,
	MERGE,
	DELETE,
	UPDATE,
	createGetters,
	createMutations,
	handleAction,
} from 'vuelpers'

const initialState = (): ConfigurationState => ({
	configureSettings: undefined,
})

const state = initialState()
const mutations = createMutations(SET, UPDATE, DELETE, MERGE)
const getters = createGetters<ConfigurationState>('configureSettings')

const actions: ActionTree<ConfigurationState, unknown> = {
	setConfigurations: ({ commit }, payload) => {
		// Decrypt the paypal configuration
		// try {
		// 	const bytes = CryptoJS.AES.decrypt(
		// 		payload.paypal,
		// 		process.env.VUE_APP_CRYPTO_PRIVATE_KEY || 'Key'
		// 	)
		// 	payload.paypal = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
		// } catch (_) {
		// 	payload.paypal = {
		// 		value: payload.paypal,
		// 		env: 'sandbox',
		// 		client: {
		// 			sandbox: '',
		// 			production: '',
		// 		},
		// 	}
		// }
		// Save the configurations
		commit(SET, { config: payload }, { root: true })
		commit(SET, { configureSettings: payload })
	},
	getConfiguration({ dispatch }, payload: any) {
		return handleAction(ConfigApi.get(payload), (res: any) => {
			dispatch('setConfigurations', res)
		})
	},
	updateConfiguration({ dispatch }, data: any) {
		return handleAction(
			ConfigursApi.post(data),
			(res: any) => {
				dispatch('setConfigurations', res)
			},
			(er: any) => {
				console.log(er)
			}
		)
	},
}

export default {
	state,
	getters,
	actions,
	mutations,
	namespaced: true,
}

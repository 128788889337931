



























































import Vue from 'vue'

import { SuperUser, User } from '@/types'
import { mapGetters } from 'vuex'
import { Accessors } from 'vue/types/options'

export default Vue.extend({
	name: 'DropdownProfile',
	computed: {
		...(mapGetters('auth', [
			'$currentUser',
			'$isLoggedIn',
			'$superUser',
		]) as Accessors<{
			$currentUser: User
			$isLoggedIn: boolean
			$superUser: SuperUser
		}>),
	},
})


















import Vue from 'vue'
import { omitEmpties } from 'vuelpers'
export default Vue.extend({
	name: 'BaseSort',
	props: {
		value: {
			default: () => ({}),
			type: Object as Vue.PropType<{
				orderByValue?: string
				orderByColumn?: string
			}>,
		},
	},
	data: () => ({
		selectedItem: '',
		items: [
			{ text: 'Price Low to High', value: 'dSellPrice_ASC' },
			{ text: 'Price High to Low', value: 'dSellPrice_DESC' },

			{ text: 'Description A to Z', value: 'vDescription_ASC' },
			{ text: 'Description Z to A', value: 'vDescription_DESC' },

			{ text: 'Quick Find (ASC)', value: 'iQuickFind_ASC' },
			{ text: 'Quick Find (DESC)', value: 'iQuickFind_DESC' },

			{ text: 'SKU (ASC)', value: 'vSKU_ASC' },
			{ text: 'SKU (DESC)', value: 'vSKU_DESC' },
		],
	}),
	watch: {
		value: {
			deep: true,
			immediate: true,
			handler(value) {
				let selectedItem = value.orderByColumn + '_' + value.orderByValue
				if (selectedItem !== this.selectedItem) {
					this.selectedItem = selectedItem
				}
			},
		},
	},
	methods: {
		onInputItem(v?: string) {
			const [column, order] = (v || '').split('_')
			this.$emit(
				'input',
				omitEmpties({
					orderByValue: order,
					orderByColumn: column,
				})
			)
		},
	},
})

import { customerApi } from '@/api'
import { ActionTree } from 'vuex'
import { createPaginaion } from 'vuelpers'
import { Customer, CustomerState } from '@/types'

import {
	// MUTATION TYPES
	SET,
	UPDATE,
	DELETE,
	PUSH,
	MERGE,
	createGetters,
	createMutations,
	handleAction,
} from 'vuelpers'
import { selectWith } from '@/helpers'

const initialState = (): CustomerState => ({
	customers: createPaginaion(),
	customer: undefined,
})

const state = initialState()
const mutations = createMutations(SET, UPDATE, DELETE, PUSH, MERGE)
const getters = createGetters<CustomerState>('customers')

const actions: ActionTree<CustomerState, unknown> = {
	getCustomers({ commit }, payload: any) {
		payload = { ...payload, with: selectWith<Customer>([{ user: [] }]) }
		return handleAction(customerApi.show(payload), (res: any) => {
			commit(SET, { customers: res })
		})
	},
	createCustomer({ commit }, payload: any) {
		return handleAction(customerApi.store(payload), (res: any) => {
			commit(MERGE, ['customers.data', [res], 'iCustomerId', 'unshift'])
		})
	},
	updateCustomer({ commit }, payload: any) {
		return handleAction(customerApi.update(payload), (res: any) => {
			commit(UPDATE, ['customers.data', res, 'iCustomerId'])
		})
	},
	getCustomerById({ commit }, id: number | string) {
		return handleAction(customerApi.index(id), (res: any) => {
			commit(SET, { customer: res })
		})
	},
	deleteCustomerById({ commit, dispatch, state }, id: number | string) {
		return handleAction(customerApi.delete(id), () => {
			commit(DELETE, ['customers.data', id, 'iCustomerId'])
			dispatch('getCustomers', {
				page: state.customers.currentPage,
				perPage: state.customers.perPage,
			})
		})
	},
	changeStatusById({ commit }, id: number | string) {
		return handleAction(customerApi.statusChange(id), (res: any) => {
			commit(UPDATE, ['customers.data', res, 'iCustomerId'])
		})
	},
}

export default {
	state,
	getters,
	actions,
	mutations,
	namespaced: true,
}















































































import Vue from 'vue'

import { mapGetters } from 'vuex'
import { Accessors } from 'vue/types/options'
import { encodeString, Pagination, TIME } from 'vuelpers'
import { Category, Config } from '@/types'
import { slugify } from '@/helpers'

export default Vue.extend({
	name: 'AppFooter',
	data: () => ({
		now: Date.now(),
		interval: null as any,
	}),
	created() {
		this.interval = setInterval(() => {
			this.now = Date.now()
		}, TIME.getMS(10, 'SEC'))
	},
	beforeDestroy() {
		if (this.interval) clearInterval(this.interval)
	},
	computed: {
		...({
			...mapGetters('category', ['$categories']),
			...mapGetters(['$config', '$logo', '$logoFallback']),
		} as Accessors<{
			$logo: string
			$config: Config
			$logoFallback: string
			$categories: Pagination<Category>
		}>),
		contactPhone(): string {
			const { vPhone, vPhoneCode } = this.$config || {}
			if (!vPhone || !vPhoneCode) return ''
			return `${vPhoneCode} ${vPhone}`
		},
		items(): any {
			return [
				{
					title: 'Shop',
					links: this.$categories.data.slice(0, 12).map((category) => ({
						icon: null,
						isStatic: false,
						routerLink: true,
						text: category.vCategory,
						path: `/categories/${slugify(
							category.vCategory
						)}/${encodeString(category.iCategoryId)}`,
					})),
				},
				{
					title: 'Company',
					links: [
						// {
						// 	text: 'About Us',
						// 	path: '#',
						// 	icon: null,
						// 	routerLink: null,
						// 	isStatic: false,
						// },
						{
							text: 'Contact US',
							path: '/contact-us',
							routerLink: true,
						},
						{
							text: 'Delivery Information',
							path: '/delivery-information',
							routerLink: true,
						},
						{
							text: 'Privacy',
							path: '/privacy',
							routerLink: true,
						},
						// {
						// 	text: 'Blog',
						// 	path: '#',
						// 	icon: null,
						// 	routerLink: null,
						// 	isStatic: false,
						// },
					],
				},
				{
					title: 'Account',
					links: [
						{
							text: 'Register',
							icon: null,
							isStatic: true,
							routerLink: false,
							handler: () => {
								this.$store.commit('auth/SET', {
									authentication: {
										dialog: true,
										persisted: false,
										currentTab: 'signup',
									},
								})
							},
						},
						{
							text: 'Login',
							icon: null,
							isStatic: true,
							routerLink: false,
							handler: () => {
								this.$store.commit('auth/SET', {
									authentication: {
										dialog: true,
										persisted: false,
										currentTab: 'signin',
									},
								})
							},
						},
					],
				},
				{
					title: 'Support',
					links: [
						{
							text: 'How it Works',
							path: '#',
							routerLink: true,
						},
						{
							text: 'FAQ',
							path: '/faq',
							routerLink: true,
						},
						{
							text: 'Return Policy',
							path: '/return-policy',
							routerLink: true,
						},

						{
							text: 'Terms And Conditions',
							path: '/terms-and-conditions',
							routerLink: true,
						},
						// {
						// 	icon: null,
						// 	isStatic: false,
						// 	routerLink: true,
						// 	text: 'Report a problem',
						// 	path: '/report-problem',
						// },
						{
							icon: 'mdi-phone',
							isStatic: false,
							routerLink: false,
							text: this.contactPhone,
							path: `tel:${this.contactPhone}`,
						},
					],
				},
				// {
				// 	title: 'Social',
				// 	links: [
				// 		{
				// 			text: 'Facebook',
				// 			path: '#',
				// 			icon: 'mdi-facebook',
				// 			routerLink: null,
				// 			isStatic: false,
				// 		},
				// 		{
				// 			text: 'Instagram',
				// 			path: '#',
				// 			icon: 'mdi-instagram',
				// 			routerLink: null,
				// 			isStatic: false,
				// 		},
				// 		{
				// 			text: 'Youtube',
				// 			path: '#',
				// 			icon: 'mdi-youtube',
				// 			routerLink: null,
				// 			isStatic: false,
				// 		},
				// 	],
				// },
			]
		},
	},
})

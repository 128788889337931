















import Vue from 'vue'
export default Vue.extend({
	name: 'BaseScrollToTop',
	computed: {
		btnRef(): Vue {
			return this.$refs.btnRef as Vue
		},
	},
	mounted() {
		window.addEventListener('scroll', this.onScroll)
	},
	methods: {
		onScrollTop() {
			window.scrollTo({
				top: 0,
				behavior: 'smooth',
			})
		},
		onScroll() {
			this.btnRef.$el.classList.toggle('show', window.scrollY > 300)
		},
	},
	beforeDestroy() {
		window.removeEventListener('scroll', this.onScroll)
	},
})

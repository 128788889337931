import { baseProductSelectWith } from './../queries'
import { pick } from 'lodash'
import { Brand, Product } from '@/types'
import { Pagination } from 'vuelpers'

export const getAdvancedSearchQuery = (query: any = {}) => {
	return {
		perPage: query.perPage || 25,
		idCategory: query.idCategory || '',
		lowestPrice: query.lowestPrice || '',
		highestPrice: query.highestPrice || '',
		allWord: query.allWord || '',
		exactPhrase: query.exactPhrase || '',
		oneWord: query.oneWord || '',
		withoutWord: query.withoutWord || '',
		with: baseProductSelectWith,
		queryArray: query.queryArray
			? query.queryArray.split(',')
			: ['vDescription', 'iProductId', 'vSKU', 'idCategory'],
	}
}

export const isEmptyProducts = (products: Pagination<Product>) => {
	return (
		products.isLoaded &&
		products.currentPage === 1 &&
		products.data.length <= 0
	)
}

export const getBasicProductInfos = (
	product: Product,
	brand: Brand
): Partial<Product> => {
	return {
		dSellPrice: brand?.dSellPrice || product?.dSellPrice || 0,
		...pick(
			product,
			'iProductId',
			'vDescription',
			'vDescription2',
			'vImage',
			'vImageAlt',
			'iSource',
			'dWeight',
			'iDeliveryId',
			'deliveryMessage',
			'vSkuRaw'
		),
	}
}















import Vue from 'vue'

export default Vue.extend({
	name: 'BaseTransition',
	props: {
		name: {
			default: 'slide-y',
			type: String as Vue.PropType<string>,
		},
		translate: {
			default: -12,
			type: Number as Vue.PropType<number>,
		},
		duration: {
			default: 250,
			type: Number as Vue.PropType<number>,
		},
	},
})

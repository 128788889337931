










































































































import Vue from 'vue'

// COMPONENTS
import CartMixin from '@/mixins/UseCart'
import DropdownCartItem from '../DropdownCartItem.vue'

export default Vue.extend({
	name: 'DropdownCart',
	mixins: [CartMixin],
	components: {
		DropdownCartItem,
	},
	data: () => ({
		menu: false,
	}),
})




















































































import Vue from 'vue'

import { User } from '@/types'
import { mapGetters } from 'vuex'
import { Accessors } from 'vue/types/options'

export default Vue.extend({
	data: () => ({
		cards: ['Today', 'Yesterday'],
		drawer: true,
		loading: false,
		links: [
			{
				icon: 'mdi-source-branch',
				text: 'Categories',
				route: '/admin/categories',
			},
			{
				icon: 'mdi-format-list-bulleted-type',
				text: 'Products Types',
				route: '/admin/product-types',
			},
			{
				icon: 'mdi-package-variant',
				text: 'Products',
				route: '/admin/product',
			},

			{ icon: 'mdi-truck-fast', text: 'Orders', route: '/admin/orders' },
			{
				icon: 'mdi-account-multiple',
				text: 'Customers',
				route: '/admin/customers',
			},
			{
				icon: 'mdi-warehouse',
				text: 'Manufacturers',
				route: '/admin/manufacturer',
			},
			{
				icon: 'mdi-information',
				text: 'Reports',
				route: '/admin/reports',
			},
			{
				icon: 'mdi-cog-outline',
				text: 'Settings',
				route: '/admin/settings',
			},
		],
	}),
	computed: {
		...({
			...mapGetters('auth', ['$currentUser']),
			...mapGetters(['$config', '$logo', '$logoFallback']),
		} as Accessors<{
			$logo: string
			$currentUser: User
			$logoFallback: string
		}>),
	},
	methods: {
		async onClickLogout() {
			this.loading = true
			await this.$store.dispatch('auth/logout')
			this.loading = false
		},
	},
	created() {
		this.$currentUser &&
			this.$currentUser.vRole === 'super_admin' &&
			this.links.splice(1, 0, {
				icon: 'mdi-account-cog',
				text: 'Admins',
				route: '/admin/admins',
			})
	},
})

import Vue from 'vue'
import store from '@/store'

import VueRouter, { RouteConfig } from 'vue-router'

import { isArray } from 'lodash'
import { Category, Role, User } from './types'
import { getRouteMeta } from 'vuelpers'
import { getDefaultRoute } from './helpers'

// LAYOUTS
import MainLayout from '@/layouts/MainLayout.vue'
import AdminLayout from '@/layouts/AdminLayout.vue'
import ProfileLayout from '@/layouts/ProfileLayout.vue'

let router: VueRouter

export const useRouter = () => router

export const createRouter = () => {
	// USING VUE ROUTER
	Vue.use(VueRouter)

	const category: Category | undefined = (window as any).CATEGORY

	// ROUTES
	const routes: Array<RouteConfig> = [
		{
			path: '/admin',
			redirect: '/admin/categories',
			component: AdminLayout,
			meta: {
				requiresAuth: true,
				requireRoles: [Role.Admin, Role.SuperAdmin],
			},
			children: [
				{
					path: 'categories',
					name: 'AdminCategory',
					component: () =>
						import(
							/* webpackChunkName: "Category" */ './views/admin/Category.vue'
						),
					meta: {
						requiresAuth: true,
						requireRoles: [Role.Admin, Role.SuperAdmin],
					},
				},
				{
					path: 'customers',
					name: 'Users',
					component: () =>
						import(
							/* webpackChunkName: "Users" */ './views/admin/Customers.vue'
						),
					meta: {
						requiresAuth: true,
						requireRoles: [Role.Admin, Role.SuperAdmin],
					},
				},
				{
					path: 'product-types',
					name: 'Product-types',
					component: () =>
						import(
							/* webpackChunkName: "Product" */ './views/admin/ProductTypes.vue'
						),
					meta: {
						requiresAuth: true,
						requireRoles: [Role.Admin, Role.SuperAdmin],
					},
				},
				{
					path: 'product',
					name: 'AdminProducts',
					component: () =>
						import(
							/* webpackChunkName: "admin-product" */ './views/admin/Product.vue'
						),
					meta: {
						requiresAuth: true,
						requireRoles: [Role.Admin, Role.SuperAdmin],
					},
				},
				{
					path: 'orders',
					name: 'AdminOrders',
					component: () =>
						import(
							/* webpackChunkName: "Order" */ './views/admin/Order.vue'
						),
					meta: {
						requiresAuth: true,
						requireRoles: [Role.Admin, Role.SuperAdmin],
					},
				},
				{
					path: 'manufacturer',
					name: 'AdminManufacturer',
					component: () =>
						import(
							/* webpackChunkName: "manufacturer" */ './views/admin/Brand.vue'
						),
					meta: {
						requiresAuth: true,
						requireRoles: [Role.Admin, Role.SuperAdmin],
					},
				},
				{
					path: 'admins',
					name: 'admin',
					component: () =>
						import(
							/* webpackChunkName: "supplier" */ './views/admin/Admin.vue'
						),
					meta: {
						requiresAuth: true,
						requireRoles: [Role.Admin, Role.SuperAdmin],
					},
				},
				{
					path: 'reports',
					name: 'Reports',
					component: () =>
						import(
							/* webpackChunkName: "reports" */ './views/admin/Reports.vue'
						),
					meta: {
						requiresAuth: true,
						requireRoles: [Role.Admin, Role.SuperAdmin],
					},
				},
				{
					path: 'settings',
					name: 'Settings',
					component: () =>
						import(
							/* webpackChunkName: "settings" */ './views/admin/Settings.vue'
						),
					meta: {
						requiresAuth: true,
						requireRoles: [Role.Admin, Role.SuperAdmin],
					},
				},
				{
					path: '*',
					name: 'AdminNotFound',
					component: () =>
						import(/* webpackChunkName: "404" */ './views/404.vue'),
				},
			],
		},
		{
			path: '/',
			component: MainLayout,
			children: [
				category
					? {
							path: '',
							name: 'MainCategory',
							props: {
								subdomain: {
									value: true,
									slug: category.vCategory,
									iCategoryId: category.iCategoryId,
								},
							},
							component: () => {
								return import(
									/* webpackChunkName: "category" */ './views/Category.vue'
								)
							},
							meta: {
								requireRoles: [Role.Customer, Role.GuestCustomer],
							},
					  }
					: {
							path: '',
							name: 'Home',
							component: () => {
								return import(
									/* webpackChunkName: "home" */ './views/Home.vue'
								)
							},
							meta: {
								requireRoles: [Role.Customer, Role.GuestCustomer],
							},
					  },
				{
					path: 'categories',
					name: 'Categories',
					component: () =>
						import(
							/* webpackChunkName: "categories" */ './views/Categories.vue'
						),
					meta: {
						requireRoles: [Role.Customer, Role.GuestCustomer],
					},
				},
				{
					path: 'categories/:slug/:id',
					name: 'Category',
					component: () =>
						import(
							/* webpackChunkName: "category" */ './views/Category.vue'
						),
					meta: {
						requireRoles: [Role.Customer, Role.GuestCustomer],
					},
				},
				{
					path: 'products',
					name: 'Products',
					component: () =>
						import(
							/* webpackChunkName: "products" */ './views/Products.vue'
						),
					meta: {
						requireRoles: [Role.Customer, Role.GuestCustomer],
					},
				},
				{
					path: 'product/advance_search',
					name: 'AdvancedSearch',
					component: () =>
						import(
							/* webpackChunkName: "products" */ './views/Products.vue'
						),
					meta: {
						requireRoles: [Role.Customer, Role.GuestCustomer],
					},
				},
				{
					path: 'products/:id',
					name: 'Product',
					component: () =>
						import(
							/* webpackChunkName: "product" */ './views/Product.vue'
						),
					meta: {
						requireRoles: [Role.Customer, Role.GuestCustomer],
					},
				},
				{
					path: 'products/:id/similar',
					name: 'SimilarProducts',
					component: () =>
						import(
							/* webpackChunkName: "products" */ './views/Products.vue'
						),
					meta: {
						requireRoles: [Role.Customer, Role.GuestCustomer],
					},
				},
				{
					path: 'cart',
					name: 'Cart',
					component: () =>
						import(/* webpackChunkName: "cart" */ './views/Cart.vue'),
					meta: {
						requireRoles: [Role.Customer, Role.GuestCustomer],
					},
				},
				{
					path: 'oem',
					name: 'OEM',
					component: () =>
						import(
							/* webpackChunkName: "suppliers" */ './views/Suppliers.vue'
						),
					meta: {
						requireRoles: [Role.Customer, Role.GuestCustomer],
					},
				},
				{
					path: 'oem/:id/products',
					name: 'OEM Products',
					component: () =>
						import(
							/* webpackChunkName: "supplier-products" */ './views/SupplierProducts.vue'
						),
					meta: {
						requireRoles: [Role.Customer, Role.GuestCustomer],
					},
				},
				{
					path: 'enquiries/:id',
					name: 'Enquiries',
					component: () => {
						return import(
							/* webpackChunkName: "enquiries" */ './views/Enquiries.vue'
						)
					},
					meta: {
						requireRoles: [Role.Customer, Role.GuestCustomer],
					},
				},
				{
					path: 'checkout',
					name: 'Checkout',
					component: () => {
						return import(
							/* webpackChunkName: "checkout" */ './views/Checkout.vue'
						)
					},
					meta: {
						requireRoles: [Role.Customer, Role.GuestCustomer],
					},
				},
				{
					path: 'info/:id',
					name: 'InfoPage',
					component: () => {
						return import(
							/* webpackChunkName: "info-page" */ './views/InfoPage.vue'
						)
					},
					meta: {
						requireRoles: [Role.Customer, Role.GuestCustomer],
					},
				},
				{
					path: 'orders',
					name: 'GuestOrders',
					component: () => {
						return import(
							/* webpackChunkName: "orders" */ './views/GuestOrders.vue'
						)
					},
					meta: {
						requiresAuth: true,
						requireRoles: [Role.GuestCustomer],
					},
				},
				{
					path: 'profile',
					component: ProfileLayout,
					meta: {
						requiresAuth: true,
						requireRoles: [Role.Customer],
					},
					children: [
						{
							path: '',
							name: 'Profile',
							component: () =>
								import(
									/* webpackChunkName: "profile" */ './views/Profile.vue'
								),
							meta: {
								requiresAuth: true,
							},
						},
						{
							path: 'update',
							name: 'UpdateProfile',
							component: () =>
								import(
									/* webpackChunkName: "profile-update" */ './views/UpdateProfile.vue'
								),
							meta: {
								requiresAuth: true,
							},
						},
						{
							path: 'orders',
							name: 'Orders',
							component: () =>
								import(
									/* webpackChunkName: "orders" */ './views/Orders.vue'
								),
							meta: {
								requiresAuth: true,
							},
						},
						{
							path: 'addresses',
							name: 'Addresses',
							component: () =>
								import(
									/* webpackChunkName: "addresses" */ './views/Addresses.vue'
								),
							meta: {
								requiresAuth: true,
							},
						},
						{
							path: 'change-password',
							name: 'ProfileChangePassword',
							component: () =>
								import(
									/* webpackChunkName: "change-password" */ './views/ChangePassword.vue'
								),
							meta: {
								requiresAuth: true,
							},
						},
						{
							path: 'deletion',
							name: 'AccountDeletion',
							component: () =>
								import(
									/* webpackChunkName: "account-deletion" */ './views/AccountDeletion.vue'
								),
							meta: {
								requiresAuth: true,
							},
						},
					],
				},
				{
					path: 'payment/callback',
					name: 'PaymentCallback',
					component: () => {
						return import(
							/* webpackChunkName: "payment-callback" */ './views/PaymentCallback.vue'
						)
					},
					meta: {
						requiresAuth: true,
						requireRoles: [Role.Customer],
					},
				},
				{
					path: '/report-problem',
					name: 'ReportProblem',
					component: () => {
						return import(
							/* webpackChunkName: "report-problem" */ './views/ReportProblem.vue'
						)
					},
					meta: {
						persistent: false,
						requiresAuth: true,
						requireRoles: [Role.Customer],
					},
				},
				{
					path: '/faq',
					name: 'FAQ',
					component: () => {
						return import(/* webpackChunkName: "faq" */ './views/FAQ.vue')
					},
					meta: {
						requireRoles: [Role.Customer],
					},
				},
				{
					path: '/contact-us',
					name: 'ContactUs',
					component: () => {
						return import(
							/* webpackChunkName: "contact-us" */ './views/ContactUs.vue'
						)
					},
					meta: {
						requireRoles: [Role.Customer],
					},
				},
				{
					path: '/return-policy',
					name: 'ReturnPolicy',
					component: () => {
						return import(
							/* webpackChunkName: "return-policy" */ './views/ReturnPolicy.vue'
						)
					},
					meta: {
						requireRoles: [Role.Customer],
					},
				},
				{
					path: '/terms-and-conditions',
					name: 'TermsAndConditions',
					component: () => {
						return import(
							/* webpackChunkName: "t&cs" */ './views/T&Cs.vue'
						)
					},
					meta: {
						requireRoles: [Role.Customer],
					},
				},
				{
					path: '/delivery-information',
					name: 'DeliveryInformation',
					component: () => {
						return import(
							/* webpackChunkName: "delivery-information" */ './views/DeliveryInformation.vue'
						)
					},
					meta: {
						requireRoles: [Role.Customer],
					},
				},
				{
					path: '/Privacy',
					name: 'Privacy',
					component: () => {
						return import(
							/* webpackChunkName: "privacy" */ './views/Privacy.vue'
						)
					},
					meta: {
						requireRoles: [Role.Customer],
					},
				},
				{
					path: '/change-password',
					name: 'ChangePassword',
					component: () => {
						return import(
							/* webpackChunkName: "change-password" */ './views/auth/ChangePassword.vue'
						)
					},
				},
				{
					path: '*',
					name: 'Page not Found!',
					component: () =>
						import(/* webpackChunkName: "404" */ './views/404.vue'),
				},
			],
		},
		{
			path: '*',
			name: 'NotFound',
			component: () =>
				import(/* webpackChunkName: "404" */ './views/404.vue'),
		},
	]

	// Create the router instance and pass the `routes` option
	router = new VueRouter({
		mode: 'history',
		base: process.env.BASE_URL,
		routes,
		scrollBehavior(_, __, savedPosition) {
			if (savedPosition) {
				return { ...savedPosition, behavior: 'smooth' }
			} else {
				return { x: 0, y: 0, behavior: 'smooth' }
			}
		},
	})

	// ROUTE GUARDS
	router.beforeEach((to, _, next) => {
		const isLoggedIn = store.getters['auth/$isLoggedIn']

		// Ensure authentication
		const requiresAuth = getRouteMeta(to, 'requiresAuth')
		if (!isLoggedIn && requiresAuth) {
			store.commit('auth/SET', {
				authentication: (v: any) => ({
					...v,
					dialog: true,
					persistent: to.meta?.persistent ?? true,
					currentTab: v.currentTab ?? 'signin',
				}),
			})
		}

		if (isLoggedIn) {
			// Ensure redirect from un auth routes
			const requiresUnAuth = getRouteMeta(to, 'requiresUnAuth')
			if (requiresUnAuth) return next('/')

			// Ensure role
			let requireRoles = getRouteMeta(to, 'requireRoles')
			if (requireRoles) {
				requireRoles = isArray(requireRoles) ? requireRoles : [requireRoles]

				const currentUser = store.state.auth.currentUser as User
				if (requireRoles && !requireRoles.includes(currentUser.vRole)) {
					const defaultRoute = getDefaultRoute(currentUser.vRole)
					if (defaultRoute !== '/') return next(defaultRoute)
					return next('/404' + to.path)
				}
			}
		}
		return next()
	})

	return router
}

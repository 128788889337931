import { selectWith } from './helpers'
import {
	Cart,
	Category,
	Config,
	Order,
	Product,
	Query,
	SortOrder,
} from './types'

export const configsWith = selectWith<Config>([
	'paypal',
	'dMinimumOrderValue',
	'dPercentage',
	'iConfigureId',
	'tMessage1',
	'tMessage2',
	'tMessage3',
	'tMessage4',
	'vAddress',
	'vDefaultCurrencyCode',
	'vExchangeCurrencyCodes',
	'vDefaultLanguage',
	'vLogo',
	'vPhoneCode',
	'vPhone',
	'vEmail',
	'vDefaultTimezone',
])

const commonProductColumns: (keyof Product)[] = [
	'iProductId',
	'idProduct',
	'idCategory',
	'dSellPrice',
	'vDescription',
	'vDescription2',
	'vBrand',
	'vImage',
	'vImageAlt',
	'iViews',
	'created_at',
	'vSKU',
	'dWeight',
	'iSource',
	'iDeliveryId',
	'iQuality',
	'vSKURaw',
	'vVariant',
	'iQuickFind',
]

export const ordersWith = selectWith<Order>([
	{
		couponDiscount: [],
		deliveryCharge: [],
		customer: [
			{
				user: [
					'iUserId',
					'vProfileImage',
					'vFirstName',
					'vLastName',
					'email',
				],
			},
		],
		orderItems: [
			{
				product: [...commonProductColumns, { brands: [] }],
			},
		],
	},
])

export const baseCategorySelectWith = selectWith<Category>([
	'iCategoryId',
	'vCategory',
	'vImg',
	'vImageAlt',
	'iListOrder',
])

export const categorySelectWith = selectWith<Category>([
	'iCategoryId',
	'vCategory',
	'vImg',
	'vImageAlt',
	'iListOrder',
	'iParent',
	{
		categories: [
			'iCategoryId',
			'iParent',
			'vCategory',
			'vImg',
			'vImageAlt',
			'iListOrder',
		],
	},
])

export const cartsQuery: Query = {
	orderByColumn: 'iCartId',
	orderByValue: SortOrder.DESC,
	with: selectWith<Cart>([
		'iCartId',
		'iQuantity',
		'idBrand',
		'idProduct',
		'iCustomerId',
		{
			brand: [],
			product: [
				...commonProductColumns,
				{
					deliveryMessage: [],
				},
			],
		},
	]),
}

export const baseProductSelectWith = selectWith<Product>([
	...commonProductColumns,
	{
		brands: [],
		deliveryMessage: [],
		timkenAliases: [{ manufacturer: [] }],
	},
])

export const productWithForReportAProblem = selectWith<Product>([
	...commonProductColumns,
	{
		brands: [],
		deliveryMessage: [],
		timkenAliases: [{ manufacturer: [] }],
		productType: [],
	},
])

export const productWithSimilarAndRelated = selectWith<Product>([
	{
		aliases: [],
		brands: [],
		productType: [],
		reviewableCustomers: [],
		productReviews: [{ customer: [{ user: [] }] }],
		timkenAliases: [{ manufacturer: [] }],
		category: [{ filterDatas: [] }],
		deliveryMessage: [],
		similarProducts: [
			{
				upSellProduct: [
					...commonProductColumns,
					{
						brands: [],
						deliveryMessage: [],
					},
				],
			},
		],
		relatedProducts: [
			{
				upSellProduct: [
					...commonProductColumns,
					{
						brands: [],
						deliveryMessage: [],
					},
				],
			},
		],
	},
])

export const productSelectWithCategory = selectWith<Product>([
	...commonProductColumns,
	{
		brands: [],
		deliveryMessage: [],
		category: ['iCategoryId', 'vCategory', 'iTotalItems'],
	},
])

export const productSelectWithSupplier = selectWith<Product>([
	...commonProductColumns,
	{
		brands: [],
		deliveryMessage: [],
		timkenAliases: [{ manufacturer: [] }],
		category: ['iCategoryId', 'vCategory'],
	},
])

import { ActionTree } from 'vuex'
import { TAX_RATE } from '@/consts'
import { cartsQuery } from '@/queries'
import { CartsApi, AddressApi, DeliveryCharges } from '@/api'
import { Address, Cart, Query, RootState, CurrentOrder } from '@/types'

import {
	// MUTATION TYPES
	SET,
	UNSHIFT,
	MERGE,
	UPDATE,
	DELETE,
	//
	Pagination,
	handleAction,
	createGetters,
	createMutations,
	createPaginaion,
} from 'vuelpers'
// import { groupBy } from 'lodash'

export interface CartsState {
	carts: Pagination<Cart>
	addresses: Pagination<Address>
	currentOrder: CurrentOrder
	deliveryCharges: Pagination<any>
}

const initialOrder = (): CurrentOrder => ({
	step: 1,
	completedStep: 0,
	orderRef: undefined,
	deliveryNotes: undefined,
	vReference: undefined,
	billingAddress: undefined,
	shippingAddress: undefined,
	paypalPaymentRef: undefined,
	differentShippingAddress: false,
	deliveryCharge: undefined,
	coupon: { vCoupon: '' },
})

const initialState = (): CartsState => ({
	carts: createPaginaion(),
	addresses: createPaginaion(),
	deliveryCharges: createPaginaion(),
	currentOrder: initialOrder(),
})

const state = initialState()
const mutations = createMutations(SET, UNSHIFT, UPDATE, MERGE, DELETE)
const getters = createGetters<CartsState>(
	'carts',
	'steps',
	'addresses',
	'currentOrder',
	'deliveryCharges',
	{
		cartSummary: (state) => {
			return state.carts.data.reduce(
				(acc, item) => {
					acc.orderQty += item.iQuantity
					acc.orderVol += (item.product?.dVolume || 0) * item.iQuantity
					acc.orderWeight += (item.product?.dWeight || 0) * item.iQuantity

					const dLength = item.product?.dLength || 0
					acc.orderLen = dLength > acc.orderLen ? dLength : acc.orderLen

					return acc
				},
				{
					orderQty: 0,
					orderLen: 0,
					orderVol: 0,
					orderWeight: 0,
					orderTotalItem: state.carts.data.length,
				}
			)
		},
		selectedDeliveryCharge: (state) => {
			return state.currentOrder.deliveryCharge
			// return state.deliveryCharges.data.find((charge) => {
			// 	return (
			// 		charge.iDeliveryChargeId ===
			// 		state.currentOrder.deliveryCharge?.iDeliveryChargeId
			// 	)
			// })
		},
		subTotal: (state) => {
			return state.carts.data.reduce((acc, cart) => {
				if (
					!cart.product ||
					!(cart?.brand?.dSellPrice || cart?.product?.dSellPrice)
				)
					return acc
				return (
					acc +
					+(cart?.brand?.dSellPrice || cart?.product?.dSellPrice) *
						cart.iQuantity
				)
			}, 0)
		},
		carriage: (_, getters) => {
			if (!getters.$selectedDeliveryCharge) return 0
			return +getters.$selectedDeliveryCharge.dCharge || 0
		},
		vatAndTax: (_, getters) => {
			return (
				(getters.$subTotal + getters.$carriage - getters.$couponDAmount) *
				TAX_RATE
			)
		},
		total: (_, getters) => {
			return (
				(+getters.$subTotal || 0) +
				(+getters.$vatAndTax || 0) +
				(+getters.$carriage || 0) -
				getters.$couponDAmount
			)
		},
		iSource1Total: (state) => {
			return state.carts.data.reduce((acc, cart) => {
				if (
					!cart.product ||
					!(cart?.brand?.dSellPrice || cart?.product?.dSellPrice) ||
					cart?.product?.iSource != 1
				)
					return acc

				return (
					acc +
					+(cart?.brand?.dSellPrice || cart?.product?.dSellPrice) *
						cart.iQuantity
				)
			}, 0)
		},
		couponDAmount(state, getters) {
			if (!state.currentOrder.coupon.iCouponId) return 0
			const {
				cFreeDelivery,
				discountAmount,
				discountPercent,
				discountShipAmount,
			} = state.currentOrder.coupon

			if (cFreeDelivery == 'Y') return getters.$carriage
			if (discountShipAmount) {
				if (discountShipAmount > getters.$carriage) return getters.$carriage
				return discountShipAmount
			}

			if (discountAmount) return discountAmount
			if (discountPercent) return (getters.$subTotal / 100) * discountPercent

			return 0
		},
	}
)

const actions: ActionTree<CartsState, RootState> = {
	async confirmOrder(_, payload: any) {
		return handleAction(CartsApi.confirmOrder(payload), (res: any) => {
			console.log('placeOrder', res)
		})
	},
	async paypalConfirmPayment(_, payload: any) {
		return handleAction(
			CartsApi.paypalConfirmPayment(payload),
			(res: any) => {
				console.log('paypalConfirmPayment', res)
			}
		)
	},
	async confirmOrderBank(_, payload: any) {
		return handleAction(CartsApi.confirmOrderBank(payload), (res: any) => {
			console.log('confirmOrderBank', res)
		})
	},
	async placeOrder(_, payload: any) {
		return handleAction(CartsApi.placeOrder(payload), (res: any) => {
			console.log('placeOrder', res)
		})
	},
	resetCurrentOrder({ commit }) {
		commit(SET, { currentOrder: initialOrder() })
	},
	setCurrentOrder({ commit }, updatedOrder: Partial<CurrentOrder>) {
		commit(SET, {
			currentOrder: (order: CurrentOrder) => ({
				...order,
				...updatedOrder,
			}),
		})
	},
	getCarts({ commit, state, rootState }, payload: Query) {
		// IF NOT LOGGED IN
		if (!rootState.auth.currentUser) {
			return commit(SET, { 'carts.isLoaded': true })
		}

		// LOOKING FOR UNSAVED CART ITEMS
		const unsavedCartItems = state.carts.data.filter((cartItem) => {
			return !cartItem.iCartId
		})

		// SAVING UNSAVED CART ITEMS TO DATABASE
		if (unsavedCartItems.length > 0) {
			handleAction(
				CartsApi.addToCart({ carts: unsavedCartItems }, payload),
				(res: any) => {
					commit(SET, {
						carts: (v: Pagination<Cart>) => ({
							...v,
							...res,
							isLoaded: true,
							isLoading: false,
						}),
					})
				}
			)
		}

		// GETTING CART ITEMS
		commit(SET, { 'carts.isLoading': true })
		return handleAction(CartsApi.index(payload), (res: any) => {
			commit(SET, {
				carts: (v: Pagination<Cart>) => ({
					...v,
					...res,
					isLoaded: true,
					isLoading: false,
				}),
			})
		})
	},
	addToCart({ commit, state, rootState }, payload: Cart) {
		// FINDING CART ITEM INDEX
		const cartIndex = state.carts.data.findIndex((cart) => {
			return (
				cart.idProduct === payload.idProduct &&
				cart.idBrand === payload.idBrand
			)
		})
		let cartItem: Partial<Cart> = {}

		// UPDATING CART ITEM
		if (cartIndex !== -1) cartItem = state.carts.data[cartIndex]
		cartItem = {
			...cartItem,
			...payload,
			iQuantity: payload.iQuantity
				? payload.iQuantity
				: (cartItem.iQuantity || 0) + 1,
		}

		const onAddToCart = (cartItems: any[]) => {
			return handleAction(
				CartsApi.addToCart({ carts: cartItems }, cartsQuery),
				(res: any) => commit(MERGE, ['carts.data', res.data, 'idBrand'])
			)
		}

		// PUSHING NEW CART ITEM TO CART
		if (cartIndex === -1) {
			// SAVING TO DATABASE
			if (rootState.auth.currentUser) onAddToCart([cartItem])
			// PUSHING TO CART
			else commit(UNSHIFT, ['carts.data', cartItem])
		}
		// EXISTING CART ITEM
		else {
			if (rootState.auth.currentUser) {
				// UPDATING TO DATABASE
				cartItem.iCartId
					? handleAction(
							CartsApi.updateCartItem(cartItem, cartsQuery),
							() => {
								commit(UPDATE, ['carts.data', cartItem, 'iCartId'])
							}
					  )
					: onAddToCart([cartItem])
			}
			// UPDAING TO LOCAL CARTS
			else commit(UPDATE, ['carts.data', cartItem, 'idBrand'])
		}
	},
	syncAddress({ commit }, payload: Address) {
		return handleAction(
			AddressApi.store(payload, {
				withColumns: 'addressable:*|county:*|country:*',
			} as Query),
			(res: Pagination<Address>) => {
				commit(
					'auth/MERGE',
					['currentUser.customer.addresses', [res], 'iAddressId'],
					{ root: true }
				)
			}
		)
	},
	getDeliveryCharges({ commit }, payload: any) {
		commit(SET, { 'deliveryCharges.isRefetching': true })
		return handleAction(DeliveryCharges.customerIndex(payload), (res: any) =>
			commit(SET, {
				deliveryCharges: {
					isRefetching: false,
					querySql: res.querySql,
					data: res.deliveryCharges,
				},
			})
		)
	},
	removeCartItem({ commit, rootState }, payload: Cart) {
		commit(DELETE, ['carts.data', payload.idBrand, 'idBrand'])
		if (rootState.auth.currentUser) {
			return handleAction(CartsApi.deleteCartItem(payload.iCartId))
		}
	},
	resetState({ commit }) {
		commit(SET, initialState())
	},
}

const cartsModule = {
	state,
	getters,
	actions,
	mutations,
	namespaced: true,
}

export type CartsModule = typeof cartsModule

export default cartsModule

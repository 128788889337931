/* eslint-disable no-useless-escape */
import { isEmpty } from 'vuelpers'

const _regex = {
	name: /^[a-zA-Z\.\- ]{3,32}$/,
	email: /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/,
	phone: /^(\+?[0-9()\s\-]{10,20})$/,
	password: /^.{8,20}$/,
	character: /^[a-zA-Z]+$/,
	number: /^[0-9\s\-]+$/,
	ip: /^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/,
}

const validators = {
	email: (v: any) => {
		return isEmpty(v) || _regex.email.test(v) || `Email is not valid.`
	},
	ip: (v: any) => {
		return _regex.ip.test(v) || `Ip Address is not valid.`
	},
	name: (v: any) => {
		return _regex.name.test(v) || `Name can only have characters`
	},
	password: (v: any) => {
		return (
			!v ||
			_regex.password.test(v) ||
			`Password can't have less than 8 characters and more than 20 characters`
		)
	},
	phone: (v: any) => {
		return (
			isEmpty(v) ||
			_regex.phone.test(v) ||
			`Please enter a valid phone number.`
		)
	},
	onlyCharacters: (v: any) => {
		return (
			_regex.character.test(v) || "Can't have special characters or numbers"
		)
	},
	required: (fieldName: any) => (v: any) => {
		return !isEmpty(v) || `${fieldName || 'Field'} is required.`
	},
	max: (len: number, fieldName: any) => (v: any) => {
		if (typeof v === 'string' && Number.isNaN(+v))
			return (
				!(v && v.length >= len) ||
				`${fieldName || 'Field'} can't have more than ${len} characters`
			)
		else if (!Number.isNaN(+v))
			return v <= len || `${fieldName || 'Field'} can't be more than ${len}.`
		return true
	},
	min: (len: any, fieldName: any) => (v: any) => {
		if (typeof v === 'string' && Number.isNaN(+v))
			return (
				!(v && v.length < len) ||
				`${fieldName || 'Field'} can't have less than ${len} characters`
			)
		else if (!Number.isNaN(+v))
			return v >= len || `${fieldName || 'Field'} can't be less than ${len}.`
		return true
	},
	number: (v: any) => {
		return _regex.number.test(v) || `Please enter a valid number.`
	},
	match: (v1: any, v2: any, fieldName: any) => {
		return v1 === v2 || `${fieldName} does not matched`
	},
	cantMatch: (v1: any, v2: any, f1 = 'Field1', f2 = 'Field2') => {
		return v1 !== v2 || `${f1} and ${f2} can't be matched`
	},
}

type Validators = typeof validators
type ValidatorKey = keyof Validators

interface CreateMixinConfig {
	rules: ValidatorKey[]
}
export const createFormMixin = (config: CreateMixinConfig) => {
	const { rules } = config
	const mRules: Partial<typeof validators> = Object.keys(validators)
		.filter((key) => (rules as string[]).includes(key))
		.reduce(
			(acc, key) => ({
				...acc,
				[key]: (validators as any)[key],
			}),
			{}
		)
	return {
		name: 'FormMixin',
		data() {
			return {
				rules: mRules,
				fieldAttrs: {
					dense: true,
					outlined: true,
					required: true,
					hideDetails: 'auto',
				},
			}
		},
	}
}

export const getValidators = (...keys: ValidatorKey[]) => {
	const mRules: Validators = Object.entries(validators)
		.filter(([key]) => keys.includes(key as ValidatorKey))
		.reduce(
			(acc, [key, value]) => ({
				...acc,
				[key]: value,
			}),
			{} as Validators
		)
	return mRules
}








































































import Vue, { PropType } from 'vue'
import { TIME } from 'vuelpers'
export default Vue.extend({
	name: 'BaseQuantity',
	props: {
		value: [String, Number],
		max: {
			type: Number as PropType<number>,
			default: Infinity,
		},
		disabled: {
			default: false,
			type: Boolean as PropType<boolean>,
		},
		accent: {
			type: Boolean as PropType<boolean>,
			default: false,
		},
		readonly: {
			default: false,
			type: Boolean as PropType<boolean>,
		},
		large: {
			type: Boolean as PropType<boolean>,
			default: false,
		},
		medium: {
			type: Boolean as PropType<boolean>,
			default: false,
		},
		inputClass: {
			type: String as PropType<string>,
			default: '',
		},
		classes: {
			type: String as PropType<string>,
			default: '',
		},
	},
	data: () => {
		return {
			mQuantity: 1,
			timeout: undefined,
		} as {
			timeout: any
			mQuantity: number
		}
	},
	watch: {
		value: {
			immediate: true,
			handler(value) {
				if (value !== this.mQuantity) {
					this.mQuantity = value || 1
				}
			},
		},
	},
	computed: {
		quantity: {
			get(): number {
				return this.mQuantity
			},
			set(v: number): void {
				if (this.readonly) return
				this.mQuantity = +v
				clearTimeout(this.timeout)
				this.timeout = setTimeout(() => {
					this.$emit('input', this.mQuantity)
					this.$emit('change', this.mQuantity)
				}, TIME.SEC)
			},
		},
	},
	methods: {
		onInputQuantity(event: InputEvent): void {
			if (!event.target || !this.$refs.inputRef) return

			const target = event.target as HTMLInputElement
			const inputRef = this.$refs.inputRef as HTMLInputElement

			const v = +target.value
			if (!v || v < 1) {
				this.quantity = 1
				inputRef.value = 1 + ''
			} else if (this.max !== Infinity && v > this.max) {
				this.quantity = this.max
				inputRef.value = this.max.toString()
			}
		},
	},
})






































































































import Vue from 'vue'

import { mapActions } from 'vuex'
import { Role, Status, User, VFormRef } from '@/types'
import { getValidators } from '@/mixins/FormMixin'
import { fetchProfile, onLoggedIn } from '@/mixins/UseAuth'
import { APP_META } from '@/consts'
import { MetaInfo } from 'vue-meta'

export default Vue.extend({
	name: 'Signup',
	metaInfo(): MetaInfo {
		return {
			...APP_META,
			title: `Signup | ${APP_META.title}`,
		}
	},
	data: () => ({
		rules: getValidators('required', 'email', 'name', 'password', 'match'),
		form: {
			valid: false,
		},
		error: false,
		isLoading: false,

		isVisiblePassword: false,
		isVisiblePasswordConfirmation: false,
		isBot: '',
		user: {
			email: '',
			password: '',
			vFirstName: '',
			vLastName: '',
			vRole: Role.Customer,
			eStatus: Status.Active,
			passwordConfirmation: '',
		} as Partial<User>,
	}),
	computed: {
		rulePasswordConfirmation(): (v: any) => true | string {
			return (v: any) => {
				return this.rules.match(v, this.user.password, 'Password')
			}
		},
	},
	methods: {
		...mapActions('auth', ['signUp', 'setAuthState']),
		onInitSignIn() {
			this.setAuthState({
				'authentication.currentTab': 'signin',
			})
		},
		async onSubmit() {
			const ref = this.$refs.formRef as VFormRef
			if (!ref.validate()) return

			if (this.isBot) {
				ref.reset()
				return this.$toast.error(
					"Great! You have proven to be a bot! I've destroyed the form the page, and acted like you successfully sent the form."
				)
			}

			this.isLoading = true
			let [error, res] = await this.signUp({
				...this.user,
				password_confirmation: this.user.passwordConfirmation,
			})
			this.isLoading = false

			if (!error) {
				onLoggedIn({
					user: {},
					token: res.token,
				})
				fetchProfile()
				ref.reset()
				this.setAuthState({
					'authentication.dialog': false,
				})
			}

			if (
				error &&
				res?.errors?.email?.includes('The email has already been taken.')
			) {
				this.$helper.toast({
					error,
					errorMessage:
						'The account already exists, If you have forgotten the password then use the link, Forgot Password.',
				})
				this.onInitSignIn()
				return
			}

			this.error = error
			this.$helper.toast({
				error,
				successMessage: 'Registration successful',
				errorMessage:
					'Error while creating your account. Please try again later',
			})
		},
	},
})







































































































































































import Vue from 'vue'

import { mapGetters } from 'vuex'
import { select } from '@/helpers'
import { Accessors } from 'vue/types/options'
import { getAdvancedSearchQuery } from '@/mixins/UseProducts'
import { useDraggableElement } from '../../composables/useDraggableElement'
import { Category, CategoryKeys, ProductKeys, Query, VFormRef } from '@/types'
import {
	Pagination,
	omitEmpties,
	queryString,
	changeLocationQuery,
} from 'vuelpers'

export default Vue.extend({
	name: 'FormAdvancedSearch',
	props: {
		submitFunction: Function,
	},
	data: () => ({
		isLoading: false,
		isFormValid: false,
		fields: [
			{ text: 'Description', value: 'vDescription' },
			{ text: 'Stk Ref', value: 'vSKU' },
			{ text: 'Product Category', value: 'idCategory' },
		],
		search: {
			perPage: '',
			idCategory: '',
			lowestPrice: '',
			highestPrice: '',
			queryArray: [],
			allWord: '',
			exactPhrase: '',
			oneWord: '',
			withoutWord: '',
		},
	}),
	created() {
		this.search = getAdvancedSearchQuery(this.$route.query)

		if (
			!this.$categories.isRefetching &&
			(!this.$categories.isLoaded ||
				(this.$categories.isLoaded &&
					this.$categories.total !== this.$categories.data.length))
		) {
			this.$store.dispatch('category/getCategories', {
				page: 1,
				perPage: 1000,
				selectColumns: select<CategoryKeys>(
					'iCategoryId',
					'vCategory',
					'vImg',
					'vImageAlt'
				),
			} as Query)
		}
	},
	mounted() {
		useDraggableElement((this.$refs.formRef as any).$el)
		// this.$store.commit('SET', { 'filter.clear': this.onClickReset })
	},
	computed: {
		...(mapGetters('category', ['$categories']) as Accessors<{
			$categories: Pagination<Category>
		}>),
	},
	methods: {
		onClickReset() {
			;(this.$refs?.formRef as VFormRef)?.reset()
			changeLocationQuery('')
			this.search = getAdvancedSearchQuery({})
			this.$emit('clear')
		},
		async onSubmit() {
			const query = omitEmpties(this.search)
			const qs = queryString.stringify(query)

			if (this.$route.name !== 'AdvancedSearch') {
				this.$router.push(`/product/advance_search?${qs}`)
				this.$emit('submitted')
			} else {
				this.isLoading = true
				changeLocationQuery(qs)
				await this.submitFunction({
					...query,
					page: 1,
					replace: true,
					pointer: 'advanced',
					selectColumns: select<ProductKeys>(
						'vDescription',
						'vSKU',
						'idCategory',
						'iProductId'
					),
				} as Query)
				this.isLoading = false
			}
		},
	},
})

import CryptoJS from 'crypto-js'
import Vue, { VueConstructor } from 'vue'
import VueRouter, { Route, RouteRecord } from 'vue-router'

import { hasHistory, onImgError } from 'vuelpers'
import { SERVER_BASE_URL } from './consts'
import { capitalize, get, isArray, isObject, isPlainObject } from 'lodash'
import {
	// AnyRecord,
	ImgSize,
	Role,
	VRef,
	Paypal,
} from './types'
import { useRouter } from './router'

export const getCombinations = (arr: string[], maxLength = arr.length - 1) => {
	const traverse = (sourceArray: any[], comboLength: number) => {
		const sourceLength = sourceArray.length
		if (comboLength > sourceLength) return []
		const combos: any[] = []
		const makeNextCombos = (
			workingCombo: any[],
			currentIndex: number,
			remainingCount: number
		) => {
			const oneAwayFromComboLength = remainingCount == 1
			for (
				let sourceIndex = currentIndex;
				sourceIndex < sourceLength;
				sourceIndex++
			) {
				const next = [...workingCombo, sourceArray[sourceIndex]]
				if (oneAwayFromComboLength) combos.push(next)
				else makeNextCombos(next, sourceIndex + 1, remainingCount - 1)
			}
		}
		makeNextCombos([], 0, comboLength)
		return combos
	}
	return [...Array(maxLength).keys()].reduce((comb, index) => {
		return comb.concat(
			traverse(arr, index + 1).map((v: any[]) => v.join(' '))
		)
	}, [] as any[])
}

export const getEnv = (env: string, fallback = ''): string => {
	const prefix = 'VUE_APP_'
	env = !env.startsWith(prefix) ? prefix + env : env
	return process.env[env] || fallback
}

export const parsePaypalConfig = (config?: string | Paypal): Paypal => {
	const newPaypal = (v?: any) => {
		return {
			value: v?.value,
			env: v?.env ?? getEnv('PAYPAL_ENV', 'sandbox'),
			client: {
				sandbox: v?.client?.sandbox ?? getEnv('PAYPAL_SANDBOX_CLIENT_ID'),
				production:
					v?.client?.production ?? getEnv('PAYPAL_PRODUCTION_CLIENT_ID'),
			},
		}
	}
	if (!config || isObject(config)) return newPaypal(config)
	try {
		const bytes = CryptoJS.AES.decrypt(
			config,
			process.env.VUE_APP_CRYPTO_PRIVATE_KEY || 'Key'
		)
		return JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
	} catch (_) {
		return newPaypal({ value: config })
	}
}

export const getRef = (ref: VRef): [Element | undefined, Vue | undefined] => {
	if (!ref) return [undefined, undefined]
	const eRef = Array.isArray(ref) ? ref[0] : ref
	if (ref instanceof Element) return [ref, undefined]
	return [(eRef as Vue).$el as Element, eRef as Vue]
}

export const getDefaultRoute = (vRole: Role) => {
	return {
		[Role.Admin]: '/admin',
		[Role.SuperAdmin]: '/admin',
		[Role.Customer]: '/',
		[Role.GuestCustomer]: '/',
	}[vRole]
}

export const select = <T>(...columns: (T | '*')[]) => {
	return [...new Set(columns)].join(',')
}

export const selectWithOld = <T>(table: string, columns: (T | '*')[]) => {
	return `${table}:${select<T>(...columns)}`
}

export const joinWiths = (...args: string[]) => {
	return args.join('|')
}

export const removeSpecialChars = (str: string) => {
	return str.replace(/[^a-zA-Z0-9 ]/g, '').trim()
}

// extends readonly unknown[]
type ArrayElement<ArrayType> = ArrayType extends readonly (infer ElementType)[]
	? ElementType
	: never

type Select<T> = keyof Required<T>
type With<T> = {
	[key in keyof Required<T>]?: Required<T>[key] extends unknown[]
		? (
				| Select<ArrayElement<Required<T>[key]>>
				| With<ArrayElement<Required<T>[key]>>
		  )[]
		: (Select<Required<T>[key]> | With<Required<T>[key]>)[]
}

export const selectWith = <T>(args: (Select<T> | With<T>)[]) => {
	return JSON.stringify(args)
}

// Generate slug from string
export const slugify = (str: string) => {
	return removeSpecialChars(str)
		.toLowerCase()
		.split(' ')
		.filter((a) => a)
		.map((a) => a.trim())
		.join('-')
}

export const isStrictSame = (value1: any, value2: any): boolean => {
	if (typeof value1 !== typeof value2) return false
	if (isArray(value1)) {
		if (value1.length !== value2.length) return false
		for (let i = 0; i < value1.length; i++) {
			if (!isStrictSame(value1[i], value2[i])) {
				return false
			}
		}
		return true
	}
	if (isPlainObject(value1)) {
		return !Object.keys(value1).some((key) => {
			return !isStrictSame(value1[key], value2[key])
		})
	}
	return value1 === value2
}

// Get Storage url
export type GetStorageUrl = typeof getStorageUrl
export const getStorageUrl = (src?: string) => {
	if (src?.startsWith('blob')) return src
	return `${SERVER_BASE_URL}/storage/${src}`
}

// Get thumbnail url from src
export type ImgSrc = typeof imgSrc
export const imgSrc = (
	src = '',
	config?: {
		size?: ImgSize
		type?:
			| 'products'
			| 'categories'
			| 'productTypes'
			| 'suppliers'
			| 'users'
			| 'reviews'
	}
) => {
	if (!src) return 'https://via.placeholder.com/512x512'
	if (['http', 'blob'].some((r) => src.startsWith(r))) return src
	const { size, type } = { size: 'tn', type: 'products', ...(config || {}) }
	if (size === 'none') return `${SERVER_BASE_URL}/storage/${type}/${src}`
	return `${SERVER_BASE_URL}/storage/${type}/${size}/${src}`
}

export const getFullName = (data: any): string => {
	if (!data) return ''
	if (data.name) return data.name
	if (data.fullName) return data.fullName
	if (data.firstName) return `${data.firstName} ${data.lastName}`
	return `${data.vFirstName} ${data.vLastName}`
}
export type GetFullName = typeof getFullName

export interface HistoryStack {
	path: string
	name?: string | null
	active: boolean
}
export const createHistoryStack = (router: VueRouter) => ({
	install(Vue: VueConstructor<Vue>) {
		const stack: HistoryStack[] = [
			{
				path: '/',
				name: 'Home',
				active: true,
			},
		]
		const syncStack = (route: Route | HistoryStack | RouteRecord) => {
			const existedIndex = stack.findIndex((r) => r.path === route.path)
			if (existedIndex === -1) {
				stack.push({
					active: false,
					path: route.path,
					name: route.name || capitalize(route.path.replace(/\//, '')),
				})
				if (stack.length > 10) stack.shift()
			} else {
				const existed = stack[existedIndex]
				stack.splice(existedIndex, 1, {
					...existed,
					name: route.name || existed.name,
				})
			}
			stack.forEach((s) => {
				s.active = s.path === route.path
			})
		}
		router.beforeEach((to, _, next) => {
			for (const route of to.matched.slice(0, -1).filter((r) => r.path)) {
				syncStack(route)
			}
			syncStack(to)
			return next()
		})
		Vue.prototype.$historyStack = Vue.observable(stack)
		Vue.prototype.$syncStack = syncStack
	},
})

export default {
	install(Vue: VueConstructor<Vue>) {
		Vue.prototype.$get = get
		Vue.prototype.$helper = {
			imgSrc,
			fullName: getFullName,
			storage: getStorageUrl,
			vImgErr: onImgError,
			toast(config: {
				error: boolean
				errorMessage?: string
				successMessage?: string
			}) {
				if (config.error) return Vue.$toast.error(config.errorMessage ?? '')
				return Vue.$toast.success(config.successMessage ?? '')
			},
			async goBack(path = '/') {
				const router = useRouter()
				return hasHistory() ? router.back() : await router.push(path)
			},
		}
	},
}

import api, { AddressApi, AuthApi, fetch } from '@/api'

import { Role } from '@/types'
import { ActionTree } from 'vuex'
import { useRouter } from '@/router'
import { getDefaultRoute } from '@/helpers'
import { onLoggedIn, onLoggedOut } from '@/mixins/UseAuth'

import type { Id, Customer, SuperUser, AuthState, RootState } from '@/types'

import {
	// MUTATION TYPES
	SET,
	MERGE,
	DELETE,
	//
	createGetters,
	createMutations,
	handleAction,
} from 'vuelpers'

const initialState = (): AuthState => ({
	accessToken: '',
	tokenExpiresAt: '',
	currentUser: null,
	superUser: undefined,
	authentication: {
		dialog: false,
		currentTab: null,
		persistent: false,
	},
})

const hasRole = (...roles: Role[]) => {
	return (state: AuthState) => {
		// Or
		if (!state?.currentUser?.vRole) return false
		return roles.includes(state?.currentUser?.vRole)

		// And
		// return roles.every((role) => {
		// 	return state?.currentUser?.vRole === role
		// })
	}
}

const state = initialState()
const mutations = createMutations(SET, DELETE, MERGE)
const getters = createGetters<AuthState>(
	'superUser',
	'currentUser',
	'accessToken',
	'tokenExpiresAt',
	'authentication',
	{
		role: 'currentUser.role',
		isLoggedIn: (s) => !!(s.accessToken && s.currentUser),
		isAdmin: hasRole(Role.Admin),
		isCustomer: hasRole(Role.Customer),
		isSuperAdmin: hasRole(Role.SuperAdmin),
		isGuestCustomer: hasRole(Role.GuestCustomer),
		isLikeAdmin: hasRole(Role.Admin, Role.SuperAdmin),
	}
)

const actions: ActionTree<AuthState, RootState> = {
	setAuthState({ commit }, payload) {
		return commit(SET, payload)
	},
	deleteAddress({ commit }, payload: Id) {
		return handleAction(AddressApi.delete(payload), () => {
			commit(DELETE, [
				'currentUser.customer.addresses',
				payload,
				'iAddressId',
			])
		})
	},
	updateProfile({ commit }, payload) {
		return handleAction(AuthApi.currentUser.update(payload), (res: any) => {
			commit(SET, { currentUser: res })
		})
	},
	updatePassword(_, payload) {
		return handleAction(AuthApi.currentUser.updatePassword(payload))
	},
	deleteAccount(_, payload) {
		return handleAction(AuthApi.currentUser.deleteAccount(payload))
	},
	async login(_, payload) {
		const [err, res] = await AuthApi.login(payload)
		if (err) return [err, res]
		return await onLoggedIn(res)
	},
	loginAsCustomer({ commit, state }, payload: Customer) {
		return handleAction(
			AuthApi.loginAsCustomer(payload.iCustomerId),
			async (res: any) => {
				// Backup the current user
				const superUser = {
					user: state.currentUser,
					accessToken: state.accessToken,
					tokenExpiresAt: state.tokenExpiresAt,
				}

				// Loggin as customer and fetching customer data
				const [loginErr, loginRes] = await onLoggedIn(res)
				if (loginErr) throw new Error(loginErr.message)

				// saving the super user and going to the customer page
				commit(SET, { superUser, 'currentUser.pretending': true })
				useRouter().push(getDefaultRoute(loginRes.vRole))
			}
		)
	},
	exitViewAsCustomer({ commit, state }) {
		const su = state.superUser as SuperUser

		api.setHeaders({ Authorization: `Bearer ${su.accessToken}` })

		return handleAction(
			// Exit from view as customer
			AuthApi.exitAsCustomer({
				iAdminId: su.user.iUserId,
				token: state.accessToken,
				iUserId: state.currentUser?.iUserId,
			}),
			async () => {
				// Loggin back to admin and fetching admin data
				const [err] = await onLoggedIn({
					user: su.user,
					token: su.accessToken,
					expiresAt: su.tokenExpiresAt,
				})

				// Something went wrong while logging back
				if (err) throw new Error(err.message)

				// Removing the temorary super user data
				commit(SET, { superUser: undefined, currentUser: su.user })

				// Going back to the admin home page
				useRouter().push(getDefaultRoute(su.user.vRole))
			},
			() => {
				api.setHeaders({ Authorization: `Bearer ${state.accessToken}` })
			}
		)
	},
	signUp(_, payload) {
		return fetch.toCallback(AuthApi.signUp(payload))
	},
	async logout({ dispatch }) {
		await AuthApi.logout()
		await onLoggedOut()
		await dispatch('carts/resetState', null, { root: true })
	},
	resetState({ commit }) {
		commit(SET, initialState())
	},
}

const authModule = {
	state,
	getters,
	actions,
	mutations,
	namespaced: true,
}

export type AuthModule = typeof authModule

export default authModule














































































































import Vue from 'vue'
import { onFilePicked, openFilePicker } from 'vuelpers'

interface Attachment {
	file: File
	src: string
}

export default Vue.extend({
	name: 'BaseDropZone',
	props: {
		value: {
			required: false,
			type: [Array, Object] as Vue.PropType<
				Attachment[] | Attachment | undefined
			>,
		},
	},
	data: () => ({
		dragging: false,
	}),
	computed: {
		hasNewAttachments(): boolean {
			return (
				this.attachments.findIndex((attachment) => {
					return !!attachment.file
				}) > -1
			)
		},
		isMultiple(): boolean {
			return Array.isArray(this.value)
		},
		attachments: {
			get(): Attachment[] {
				return Array.isArray(this.value)
					? this.value
					: this.value
					? [this.value]
					: []
			},
			set(v: Attachment[]) {
				this.$emit('input', this.isMultiple ? v : v[0])
			},
		},
	},
	methods: {
		onRemoveAttachment(index: number) {
			this.attachments = this.attachments.filter((_, i) => {
				return i !== index
			})
		},
		onResetChanges() {
			this.attachments = this.attachments.filter((attach) => {
				return !attach.file
			})
		},
		onAttchment(attachment: Attachment | Attachment[]) {
			// Ensure array
			const attachments = Array.isArray(attachment)
				? attachment
				: [attachment]

			if (this.isMultiple) {
				// Finding unique attachments
				const newAttachments = attachments.filter((attachment) => {
					return !this.attachments.some((attach) => {
						if (!attach.file) return attach.src === attachment.src
						return attach.file.name === attachment.file.name
					})
				})

				// Pushing new attachments
				this.attachments.push(...newAttachments)
			}
			// Single
			else {
				this.attachments = attachments
			}

			// Fire event to parent
			// this.$emit('attachments', this.attachments)
			this.dragging = false
		},
		onDrop(e: DragEvent) {
			const event = {
				target: {
					files: e?.dataTransfer?.files || [],
				},
			}
			return onFilePicked(
				event,
				{
					multiple: this.isMultiple,
				},
				this.onAttchment
			)
		},
		onOpenFilePicker() {
			return openFilePicker(
				{
					multiple: this.isMultiple,
				},
				this.onAttchment
			)
		},
	},
})












































import Vue from 'vue'

import Flag from './base/Flag.vue'

import { mapGetters } from 'vuex'
import { Config, Currency } from '@/types'
import { Accessors } from 'vue/types/options'
import {
	getCurrency,
	fallbackCurrency,
	getCurrencySign,
} from '@/mixins/UseCurrency'

export default Vue.extend({
	name: 'CurrencyChanger',
	components: {
		Flag,
	},
	props: {
		fallbackCurrency: {
			default: () => fallbackCurrency,
			type: Object as Vue.PropType<Currency>,
		},
	},
	watch: {
		'$config.vDefaultCurrencyCode': {
			immediate: true,
			handler(defaultCurrency) {
				if (defaultCurrency && !this.$currentCurrency) {
					this.currenyModel = defaultCurrency
				}
			},
		},
	},
	computed: {
		...(mapGetters(['$config', '$currentCurrency']) as Accessors<{
			$config?: Config
			$currentCurrency?: Currency
		}>),
		currencies(): Currency[] {
			if (!this.$config) return []
			return this.$config.vExchangeCurrencyCodes
		},
		currenyModel: {
			get(): string {
				const currency = getCurrency(
					this.$currentCurrency,
					this.fallbackCurrency
				)
				if (currency) return currency.code
				return this.fallbackCurrency.code
			},
			set(v: string): void {
				const currency = getCurrency(v, this.fallbackCurrency) || {
					code: v,
				}
				this.$store.commit('SET', { currentCurrency: currency })
			},
		},
	},
	methods: {
		getCurrencySign,
	},
})

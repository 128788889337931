


































import Vue from 'vue'
export default Vue.extend({
	name: 'BaseButton',
	props: {
		disabled: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		height: {
			type: Number,
			default: 56,
		},
		pill: {
			type: Boolean,
			default: false,
		},
		dark: {
			type: Boolean,
			default: false,
		},
		bold: {
			type: Boolean,
			default: true,
		},
		small: {
			type: Boolean,
			default: false,
		},
		accent: {
			type: Boolean,
			default: false,
		},
	},
})

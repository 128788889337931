import { ActionTree } from 'vuex'
import { getStorageUrl } from '@/helpers'
import { ConfigApi } from './../../api/auth.api'
import { Config, Currency } from './../../types'
import {
	// MUTATION TYPES
	SET,
	// METHODS
	createGetters,
	createMutations,
	handleAction,
} from 'vuelpers'

class State {
	theme = {
		isDark: true,
	}
	filter = {
		dialog: false,
	}
	config?: Config = undefined
	currentCurrency?: Currency = undefined
}

const state = new State()
const mutations = createMutations(SET)
const getters = createGetters<State>(
	'locale',
	'filter',
	'config',
	'currentCurrency',
	{
		logoFallback(): string {
			return require('../../assets/img/logo.png')
		},
		logo(state): string {
			if (state.config?.vLogo) {
				return getStorageUrl('configure/' + state.config.vLogo)
			}
			return require('../../assets/img/logo.png')
		},
	}
)

const actions: ActionTree<State, unknown> = {
	fetchConfig({ dispatch }, payload = {}) {
		return handleAction(ConfigApi.get(payload), (res: any) => {
			dispatch('configure/setConfigurations', res, { root: true })
		})
	},
	resetState({ commit }) {
		commit(SET, { ...new State() })
	},
}

export default {
	state,
	getters,
	actions,
	mutations,
}


























import Vue, { PropType } from 'vue'
import { Product } from '@/types'

export default Vue.extend({
	name: 'BaseProductCardChip',
	props: {
		product: {
			required: true,
			type: Object as PropType<Product>,
		},
	},
})

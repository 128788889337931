import { County } from './../../types'
import { Pagination } from 'vuelpers/lib/vuex'
import { countyApi } from '@/api'
import { ActionTree } from 'vuex'
import { createPaginaion } from 'vuelpers'
import { CountyState } from '@/types'

import {
	// MUTATION TYPES
	SET,
	UPDATE,
	DELETE,
	PUSH,
	createGetters,
	createMutations,
	handleAction,
} from 'vuelpers'

const initialState = (): CountyState => ({
	counties: createPaginaion(),
	county: undefined,
})

const state = initialState()
const mutations = createMutations(SET, UPDATE, DELETE, PUSH)
const getters = createGetters<CountyState>('county', 'counties')

const actions: ActionTree<CountyState, unknown> = {
	getCounties({ commit }, payload: any) {
		commit(SET, { 'counties.isRefetching': true })
		payload = { ...payload, with_columns: 'country:*' }
		return handleAction(
			countyApi.index(payload),
			(res: Pagination<County>) => {
				commit(SET, {
					counties: {
						...res,
						isRefetching: false,
					},
				})
			}
		)
	},
	getCountyById({ commit }, id: number | string) {
		return handleAction(countyApi.show(id), (res: any) => {
			commit(SET, { category: res })
		})
	},
	createCounty({ commit }, data: any) {
		return handleAction(countyApi.store(data), (res: any) => {
			commit(PUSH, ['counties.data', res])
		})
	},
	updateCounty({ commit }, data: any) {
		return handleAction(
			countyApi.update(data),
			(res: any) => {
				commit(UPDATE, ['counties.data', res, 'iCountyId'])
			},
			(er: any) => {
				console.log(er)
			}
		)
	},
	deleteCountyById({ commit }, id: number | string) {
		return handleAction(countyApi.delete(id), () => {
			commit(DELETE, ['counties.data', id, 'iCountyId'])
		})
	},
}

export default {
	state,
	getters,
	actions,
	mutations,
	namespaced: true,
}

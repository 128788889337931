import Vue from 'vue'
import store from '@/store'

import { Brand, Cart, Product } from '@/types'
import { mapGetters, mapActions } from 'vuex'
import { Accessors } from 'vue/types/options'
import { getBasicProductInfos } from './UseProducts'

type ArrayMethods = 'some' | 'find' | 'filter' | 'findIndex'

export const getBrand = <T extends ArrayMethods>(
	product: Product,
	method?: T
): T extends 'some'
	? boolean
	: T extends 'find'
	? Brand | undefined
	: T extends 'filter'
	? Brand[]
	: T extends 'findIndex'
	? number
	: any => {
	const mMethod: ArrayMethods = method || 'find'
	return product.brands?.[mMethod]((brand) => {
		return (
			brand.vSkuRaw === product.vSkuRaw &&
			brand.iQuality === product.iQuality
		)
	}) as any
}

export const getCartItem = (
	item: Cart | Product,
	method: 'some' | 'find' | 'filter' | 'findIndex' = 'find'
) => {
	return store.state.carts.carts.data[method]((cart) => {
		if ('iCartId' in item) return cart.iCartId === item.iCartId
		const brand = getBrand(item, 'find')
		return (
			cart.idProduct === item.idProduct &&
			brand &&
			brand.iBrandsId === cart.idBrand
		)
	})
}

export const checkIsInCart = (item: Cart | Product) => {
	return !!getCartItem(item)
}

export const onAddToCart = (product: Product, brand: Brand, quantity = 1) => {
	return store.dispatch('carts/addToCart', {
		brand,
		iQuantity: quantity,
		idBrand: brand.iBrandsId,
		idProduct: product.idProduct,
		product: getBasicProductInfos(product, brand),
	} as Cart)
}

const UseCart = Vue.extend({
	name: 'UseCart',
	computed: {
		// ...(mapGetters('auth', ['$isLoggedIn']) as Accessors<{
		// 	$isLoggedIn: boolean
		// }>),
		...(mapGetters('carts', [
			'$carts',
			'$total',
			'$subTotal',
			'$vatAndTax',
		]) as Accessors<{
			$carts: Cart[]
			$subTotal: number
			$vatAndTax: number
			$total: number
		}>),
	},
	methods: {
		checkIsInCart,
		...mapActions('carts', ['getCarts']),
	},
	watch: {
		// $isLoggedIn: {
		// 	immediate: true,
		// 	handler(val: boolean) {
		// 		if (!val) return
		// 		this.getCarts(cartsQuery)
		// 	},
		// },
	},
})

export default UseCart

import { Id, Query } from '@/types'
import api, { fetch } from './index'

export const AuthApi = {
	logout: () => api.post('/sign-out', {}),
	login: (data: any) => api.post('/login', data),
	signUp: (data: any) => fetch.post('/create-account', data),
	loginAsCustomer: (id: Id) => api.get('/customers/starting-view/' + id),
	exitAsCustomer: (data: any) => api.post('/customers/closing-view', data),
	createGuestAccount: (data?: any) => api.post('/create-guest-account', data),
	currentUser: {
		get: () => api.get('/user/profile'),
		update: (data: any) => api.post('/user/update-profile', data),
		updatePassword: (data: any) => api.put('/user/update-password', data),
		deleteAccount: (data: any) => api.put('/user/delete-account', data),
	},
}

export const CouponsApi = {
	check: (data: any) => api.post('/coupons/check-coupon-code', data),
}

export const ReviewApi = {
	post: (body: FormData) => api.post('/reviews', body),
}

export const ReportsApi = {
	get: (query: any) => api.get('/reports', query),
	post: (body: FormData) => api.post('/reports/customers/new', body),
}

export const OrdersApi = {
	getHpp: (query: Query) => api.get('/order/get-hpp', query),
	index: (query: Query) => api.get('/order', query),
	updateStatus: (data: any) => api.post('/order/update-status', data),
}

export const ConfigApi = {
	get: (query?: Query) => api.get('/configurations', query),
}

export const CategoriesApi = {
	index: (query: Query) => api.get('/categories', query),
	show: (id: Id, query: Query) => api.get('/categories/show/' + id, query),
	store: (data: any) => api.post('/categories/store', data),
	update: (data: any, query?: Query) => {
		return api.post('/categories/update', data, query)
	},
	delete: (id: any) => api.delete('/categories/delete/' + id),
}

export const CartsApi = {
	index: (query: Query) => api.get('/carts', query),
	addToCart: (data: any, query?: Query) => api.post('/carts', data, query),
	makeEmpty: () => api.get('/carts/make-empty'),
	updateCartItem: (data: any, query?: Query) => {
		return api.patch('/carts', data, query)
	},
	deleteCartItem: (id: Id) => {
		return api.delete('/carts?iCartId=' + id)
	},
	placeOrder: (data: any) => {
		return api.post('/order', data)
	},
	confirmOrder: (data: any) => {
		return api.post('/order/confirm', data)
	},
	paypalConfirmPayment: (data: any) => {
		return api.post('/paypal/payment/confirm', data)
	},
	confirmOrderBank: (data: any) => {
		return api.post('/bank/place/order', data)
	},
}

export const AddressApi = {
	index: (query: Query) => api.get('/addresses', query),
	store: (data: any, query?: Query) =>
		api.post('/addresses/store', data, query),
	delete: (id: Id) => api.delete('/addresses/' + id),
}

export const DeliveryCharges = {
	index: (query: Query) => api.get('/delivery-charges', query),
	customerIndex: (data: any) =>
		api.post('/delivery-charges/checkout/get-delcharge', data),
}

export const ProductsApi = {
	index: (query: any) => api.get('/products', query),
	show: (id: Id, query: Query) => api.get(`/products/${id}`, query),
	getByIdProduct: (idProduct: Id, query: Query) => {
		return api.get(`/products/id-product/${idProduct}`, query)
	},

	store: (data: any) => api.post('/products/store', data),
	update: (data: any) => api.post('/products/update', data),
	delete: (id: any) => api.delete('/products/delete/' + id),
	getRelatedProducts: (id: Id, query: Query) => {
		return api.get(`/products/${id}/related`, query)
	},
	getSimilarProducts: (id: Id, query: Query) => {
		return api.get(`/products/${id}/similar`, query)
	},
}

export const manufacturererApi = {
	index: (query: any) => api.get('/manufacturers', query),
	show: (id: Id, query?: Query) => api.get('/manufacturers/show/' + id, query),
	store: (data: any) => api.post('/manufacturers/store', data),
	update: (data: any) => api.post('/manufacturers/update', data),
	delete: (id: any) => api.delete('/manufacturers/delete/' + id),
	groupCounts: (query?: any) => api.get('/manufacturers/group-counts', query),
}

export const SuppliersApi = {
	show: (query: any) => api.get('/suppliers/index', query),
	index: (query: any) => api.get('/suppliers/show', query),
	store: (data: any) => api.post('/suppliers/store', data),
	update: (data: any) => api.post('/suppliers/update', data),
	delete: (id: any) => api.delete('/suppliers/delete/' + id),
}
export const AdminsApi = {
	show: (query: any) => api.get('/admins/index', query),
	index: (query: any) => api.get('/admins/show', query),
	store: (data: any) => api.post('/admins/store', data),
	update: (data: any) => api.post('/admins/update', data),
	delete: (id: any) => api.delete('/admins/delete/' + id),
}
export const customerApi = {
	show: (query: any) => api.get('/customers', query),
	index: (query: any) => api.get('/customers/show', query),
	store: (data: any) => api.post('/customers/store', data),
	statusChange: (id: any) => api.get('/customers/active-or-inactive/' + id),
	delete: (id: any) => api.delete(`/customers/${id}/delete`),
	update: (data: any) => {
		return api.put(`/customers/${data.iCustomerId}/update`, data)
	},
}
export const countryApi = {
	show: (query: any) => api.get('/countries/show', query),
	index: (query: any) => api.get('/countries/index', query),
	store: (data: any) => api.post('/countries/store', data),
	update: (data: any) => api.put('/countries/update', data),
	delete: (id: any) => api.delete('/countries/delete/' + id),
}
export const countyApi = {
	show: (query: any) => api.get('/counties/show', query),
	index: (query: any) => api.get('/counties/index', query),
	store: (data: any) => api.post('/counties/store', data),
	update: (data: any) => api.put('/counties/update', data),
	delete: (id: any) => api.delete('/counties/delete/' + id),
}
export const productTypeApi = {
	show: (query: any) => api.get('/product-types/index', query),
	index: (query: any) => api.get('/product-types/show', query),
	store: (data: any) => api.post('/product-types/store', data),
	update: (data: any) => api.post('/product-types/update', data),
	delete: (id: any) => api.delete('/product-types/delete/' + id),
}
export const filtersDataApi = {
	show: (query: any) => api.get('/filters-data/show', query),
	index: (query: any) => api.get('/filters-data', query),
	store: (data: any) => api.post('/filters-data/store', data),
	update: (data: any) => api.put('/filters-data/update', data),
	delete: (id: any) => api.delete('/filters-data/delete/' + id),
}
export const ConfigursApi = {
	show: (query: any) => api.get('/configurations', query),
	post: (data: any) => api.post('/configurations', data),
}

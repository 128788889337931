

































































































































































































































import Vue, { PropType } from 'vue'

import { mapActions } from 'vuex'
import { groupBy, truncate } from 'lodash'
import { Alias, Brand, Cart, Product } from '@/types'
import { getBrand, getCartItem } from '@/mixins/UseCart'
import { getBasicProductInfos } from '@/mixins/UseProducts'

import BaseQuantity from './BaseQuantity.vue'

export default Vue.extend({
	name: 'BaseProductCard',
	components: {
		BaseQuantity,
	},
	props: {
		listItem: {
			type: Boolean as PropType<boolean>,
			default: false,
		},
		product: {
			required: true,
			type: Object as PropType<Product>,
		},
	},
	data: () => ({
		brand: undefined as Brand | undefined,
	}),
	computed: {
		aliases(): Alias[] {
			if (!this?.product?.aliases) return []
			return [...this.product.aliases].sort((a, b) => {
				return a.vSkuRaw > b.vSkuRaw ? 1 : -1
			})
		},
		aliasGroups(): { [key: string]: Alias[] } {
			return groupBy(this.aliases, 'vCo')
		},
		thumbnail(): string {
			const vImage = this.product.vImage || ''
			return this.$helper.imgSrc(vImage.split(',')[0])
		},
		cartItem(): Cart | undefined {
			return getCartItem({
				...this.product,
				brands: this.brand ? [this.brand] : this.product.brands,
			}) as Cart | undefined
		},
		isSearch(): boolean {
			return (
				this.$route.path === '/products' &&
				this.$route.query?.type === 'search'
			)
		},
	},
	methods: {
		...mapActions('carts', ['addToCart']),
		...mapActions('products', ['createBrand']),
		truncate,
		async onAddToCart() {
			const brand = getBrand(this.product, 'find')
			if (!brand) {
				const [err, newBrand] = await this.createBrand(this.product)
				if (err) return this.$toast.error(err.message)
				this.brand = newBrand
			}
			// Has Brand
			else this.brand = brand

			if (!this.brand) {
				this.$toast.error('Error getting brand informations.')
			}

			this.addToCart({
				iQuantity: 1,
				brand: this.brand,
				idBrand: this.brand?.iBrandsId,
				idProduct: this.product.idProduct,
				product: getBasicProductInfos(this.product, this.brand as Brand),
			} as Cart)
		},
		onChangeQuantity(quantity: number) {
			let cartItem = {
				idBrand: this.brand?.iBrandsId,
				idProduct: this.product.idProduct,
				product: getBasicProductInfos(this.product, this.brand as Brand),
			} as Cart

			if (this.cartItem) {
				cartItem = { ...cartItem, ...this.cartItem }
			}

			cartItem.iQuantity = quantity
			this.addToCart(cartItem)
		},
	},
})

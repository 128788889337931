







































import Vue from 'vue'
import { isFunction } from 'lodash'

export type BaseTableAction = {
	icon?: string
	text?: string
	event?: string
	divider?: boolean
}
export default Vue.extend({
	name: 'BaseTableActions',
	props: {
		item: {
			required: true,
			type: Object as Vue.PropType<any>,
		},
		actions: {
			type: Array as Vue.PropType<BaseTableAction[]>,
			default: () => [
				{ text: 'View details', event: 'view', icon: 'mdi-eye-outline' },
				{ text: 'Update', event: 'update', icon: 'mdi-pencil-outline' },
				{ divider: true },
				{ text: 'Delete', event: 'delete', icon: 'mdi-delete-outline' },
			],
		},
	},
	data: () => ({
		selectedAction: null,
	}),
	methods: {
		isFunction,
	},
})
















































































































import Vue from 'vue'

import { Attachment, SuperUser, User } from '@/types'
import { mapGetters, mapActions } from 'vuex'
import { Accessors } from 'vue/types/options'
import { openFilePicker, toFormData } from 'vuelpers'
// import { last } from 'lodash'

interface LinkItem {
	label: string
	icon: string
	path?: string
	action?: () => void
}

export default Vue.extend({
	name: 'ProfileLayout',
	data: () => ({
		drawer: false,
		isUploadingProfileImage: false,
	}),
	computed: {
		...(mapGetters('auth', ['$currentUser', '$superUser']) as Accessors<{
			$currentUser: User
			$superUser: SuperUser
		}>),
		links(): LinkItem[] {
			const links: LinkItem[] = [
				{
					label: 'Profile',
					path: '/profile',
					icon: 'mdi-account',
				},
				{
					label: 'My Orders',
					path: '/profile/orders',
					icon: 'mdi-package-variant-closed',
				},

				{
					label: 'Edit Account',
					path: '/profile/update',
					icon: 'mdi-account-edit-outline',
				},
				{
					label: 'Address Book',
					path: '/profile/addresses',
					icon: 'mdi-map-outline',
				},
				{
					label: 'Change Password',
					path: '/profile/change-password',
					icon: 'mdi-lock-open',
				},
				// {
				// 	label: 'Delete Account',
				// 	path: '/profile/deletion',
				// 	icon: 'mdi-delete-forever-outline',
				// },
			]
			if (!this.$superUser) {
				links.push({
					label: 'Logout',
					icon: 'mdi-location-exit',
					action: () => {
						this.$store.dispatch('auth/logout')
					},
				})
			}
			return links
		},
	},
	methods: {
		...mapActions('auth', ['updateProfile', 'setAuthState']),
		onClickUploadImage() {
			openFilePicker({}, async (image: Attachment) => {
				this.isUploadingProfileImage = true
				this.setAuthState({
					'currentUser.vProfileImage': image.src,
				})
				let [err] = await this.updateProfile(
					toFormData({
						vProfileImage: image.file,
						iUserId: this.$currentUser.iUserId,
					})
				)

				this.isUploadingProfileImage = false
				this.$helper.toast({
					error: !!err,
					successMessage: 'Profile image updated successfully.',
					errorMessage:
						'Error while uploading profile image. Please try again.',
				})
			})
		},
	},
})

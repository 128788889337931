import { omit, pick } from 'lodash'
import { ActionTree } from 'vuex'
import api, { filtersDataApi, ProductsApi } from '@/api'
import {
	CategoryProducts,
	FilterObject,
	Product,
	ProductKeys,
	ProductsState,
	Query,
	SortOrder,
	Brand,
	SupplierProducts,
} from '@/types'

import {
	// MUTATION TYPES
	SET,
	MERGE,
	DELETE,
	UPDATE,
	UNSHIFT,
	handleAction,
	createGetters,
	createMutations,
	createPaginaion,
	Pagination,
	// sleep,
} from 'vuelpers'

const initialState = (): ProductsState => ({
	product: undefined,
	products: createPaginaion({ perPage: 25 }),
	searchProducts: createPaginaion({ perPage: 25 }),
	topProducts: createPaginaion({ perPage: 25 }),
	similarProducts: createPaginaion({ perPage: 25 }),
	relatedProducts: createPaginaion({ perPage: 25 }),
	newArrivedProducts: createPaginaion({ perPage: 25 }),
	// Category
	currentCategoryId: null,
	categoryProducts: [],
	// Supplier
	currentOemReference: null,
	supplierProducts: [],
	// Recent
	recentProductsIds: [],
	recentProducts: createPaginaion({ perPage: 25 }),
})

const initialFilter = (v: Partial<FilterObject> = {}): FilterObject => ({
	size: v.size || [],
	seam: v.seam || [],
	type: v.type || [],
	face: v.face || [],
	grade: v.grade || [],
	fitting: v.fitting || [],
	schedule: v.schedule || [],
	pressure: v.pressure || [],
	standard: v.standard || [],
})

const state = initialState()
const mutations = createMutations(SET, UPDATE, MERGE, DELETE, UNSHIFT)
const getters = createGetters<ProductsState>(
	'product',
	'products',
	'searchProducts',
	'topProducts',
	'recentProducts',
	'similarProducts',
	'relatedProducts',
	'newArrivedProducts',
	'recentProductsIds',
	{
		sortedRecentProductsIds(state) {
			return state.recentProductsIds.sort((a, b) => {
				return b.lastViewedAt - a.lastViewedAt
			})
		},
		sortedRecentProducts(state) {
			return state
		},
		currentCategoryProducts: (state) => {
			if (!state.currentCategoryId) return
			return state.categoryProducts.find((category) => {
				return category.iCategoryId === state.currentCategoryId
			})
		},
		currentSupplierProducts: (state) => {
			if (!state.currentOemReference) return
			return state.supplierProducts.find((supplier) => {
				return supplier.vReference === state.currentOemReference
			})
		},
	}
)

const actions: ActionTree<ProductsState, unknown> = {
	createBrand(_, product: Product) {
		return api.post('/brand/new', {
			vSKURaw: `${product.vSkuRaw}`,
			...pick(product, 'idProduct', 'iQuality', 'dSellPrice', 'vBrand'),
		} as Partial<Brand>)
	},
	getProducts(
		{ commit, state: { products } },
		{ replace = false, ...payload } = {}
	) {
		replace = replace ?? payload.page <= 1 //? false : replace

		// LOADING
		commit(SET, { 'products.isRefetching': true })
		if (!products.data.length) {
			commit(SET, { 'products.isLoading': true })
		}

		// API CALL
		return handleAction(ProductsApi.index(payload), (res: any) => {
			commit(SET, {
				products: (v: typeof products) => ({
					...v,
					...(replace ? res : omit(res, 'data')),
					isLoaded: true,
					isLoading: false,
					isRefetching: false,
				}),
			})
			!replace &&
				commit(MERGE, ['products.data', res.data, 'iProductId', 'push'])
		})
	},
	getSearchProducts({ commit }, payload = {}) {
		delete payload.replace

		if (payload.q) {
			payload.vSearchText = payload.q
			delete payload.q
		}

		const useCommit = (
			callback: (v: Pagination<Product>) => Partial<Pagination<Product>>
		) => {
			commit(SET, { searchProducts: (v: any) => ({ ...v, ...callback(v) }) })
		}

		// LOADERS
		useCommit((v) => ({
			isLoaded: payload.page > 1,
			isLoading: payload.page === 1 || !v.isLoaded,
			isRefetching: payload.page > 1 || v.isLoaded,
		}))

		// API CALL
		return handleAction(api.get('/search/products', payload), (res: any) => {
			useCommit((v) => ({
				...omit(res.products, 'data'),
				phase: res.phase,
				querySql: res.querySql,
				sqlQueryArray: res.sqlQueryArray,
				data: payload.page === 1 ? res.products.data : v.data,
				isLoaded: true,
				isLoading: false,
				isRefetching: false,
			}))
			payload.page > 1 &&
				commit(MERGE, [
					'searchProducts.data',
					res.products.data,
					'iProductId',
					'push',
				])
		})
	},
	getAdminProducts(
		{ commit, state: { products } },
		{ replace = false, ...payload } = {}
	) {
		replace = replace ?? payload.page <= 1

		// LOADING
		commit(SET, { 'products.isRefetching': true })
		if (!products.data.length) {
			commit(SET, { 'products.isLoading': true })
		}

		// API CALL
		return handleAction(ProductsApi.index(payload), (res: any) => {
			commit(SET, {
				products: (v: typeof products) => ({
					...v,
					...(replace ? res : omit(res, 'data')),
					isLoaded: true,
					isLoading: false,
					isRefetching: false,
				}),
			})
			!replace && commit(SET, { 'products.data': res.data })
		})
	},
	getTopProducts(
		{ commit, state: { topProducts } },
		payload: Query<ProductKeys>
	) {
		// LOADING
		commit(SET, { 'topProducts.isRefetching': true })
		if (!topProducts.data.length) {
			commit(SET, { 'topProducts.isLoading': true })
		}

		payload = {
			...payload,
			orderByColumn: 'iViews',
			orderByValue: SortOrder.DESC,
		}

		// API CALL
		return handleAction(ProductsApi.index(payload), (res: any) => {
			commit(SET, {
				topProducts: (v: typeof topProducts) => ({
					...v,
					...omit(res, 'data'),
					isLoaded: true,
					isLoading: false,
					isRefetching: false,
				}),
			})
			commit(MERGE, ['topProducts.data', res.data, 'iProductId', 'push'])
		})
	},
	getRecentProducts(
		{ commit, state: { recentProducts } },
		payload: Query<ProductKeys>
	) {
		// LOADING
		commit(SET, {
			'recentProducts.isLoading': !recentProducts.isLoaded,
			'recentProducts.isRefetching': recentProducts.isLoaded,
		})

		// API CALL
		return handleAction(ProductsApi.index(payload), (res: any) => {
			commit(SET, {
				recentProducts: (v: typeof recentProducts) => ({
					...v,
					...omit(res, 'data'),
					isLoaded: true,
					isLoading: false,
					isRefetching: false,
				}),
			})
			commit(MERGE, ['recentProducts.data', res.data, 'iProductId', 'push'])
		})
	},
	getNewArrivedProductsProducts(
		{ commit, state: { newArrivedProducts } },
		payload: Query<ProductKeys>
	) {
		// LOADING
		commit(SET, { 'newArrivedProducts.isRefetching': true })
		if (!newArrivedProducts.data.length) {
			commit(SET, { 'newArrivedProducts.isLoading': true })
		}

		payload = {
			...payload,
			orderByColumn: 'created_at',
			orderByValue: SortOrder.DESC,
		}

		// API CALL
		return handleAction(ProductsApi.index(payload), (res: any) => {
			commit(SET, {
				newArrivedProducts: (v: typeof newArrivedProducts) => ({
					...v,
					...omit(res, 'data'),
					isLoaded: true,
					isLoading: false,
					isRefetching: false,
				}),
			})
			commit(MERGE, [
				'newArrivedProducts.data',
				res.data,
				'iProductId',
				'push',
			])
		})
	},
	getSimilarProducts(
		{ commit, state: { similarProducts } },
		payload: Query<ProductKeys>
	) {
		// LOADING
		commit(SET, { 'similarProducts.isRefetching': true })
		if (!similarProducts.data.length) {
			commit(SET, { 'similarProducts.isLoading': true })
		}
		// API CALL
		return handleAction(
			ProductsApi.getSimilarProducts(payload.iProductId, payload),
			(res: any) => {
				commit(SET, {
					similarProducts: (v: typeof similarProducts) => ({
						...v,
						...omit(res, 'data'),
						isLoaded: true,
						isLoading: false,
						isRefetching: false,
					}),
				})
				commit(MERGE, [
					'similarProducts.data',
					res.data,
					'iProductId',
					'push',
				])
			}
		)
	},
	getRelatedProducts(
		{ commit, state: { relatedProducts } },
		payload: Query<ProductKeys>
	) {
		// LOADING
		commit(SET, { 'relatedProducts.isRefetching': true })
		if (!relatedProducts.data.length) {
			commit(SET, { 'relatedProducts.isLoading': true })
		}
		// API CALL
		return handleAction(
			ProductsApi.getRelatedProducts(payload.iProductId, payload),
			(res: any) => {
				commit(SET, {
					relatedProducts: (v: typeof relatedProducts) => ({
						...v,
						...omit(res, 'data'),
						isLoaded: true,
						isLoading: false,
						isRefetching: false,
					}),
				})
				commit(MERGE, [
					'relatedProducts.data',
					res.data,
					'iProductId',
					'push',
				])
			}
		)
	},
	syncRecentProducts(
		{ commit, state: { recentProductsIds } },
		payload: Product
	) {
		const ids = [...recentProductsIds]
		const existedIndex = ids.findIndex(
			(review) => review.id == payload.iProductId
		)
		let toAppend
		if (existedIndex === -1) {
			toAppend = { id: payload.iProductId, lastViewedAt: Date.now() }
		} else {
			toAppend = { ...ids[existedIndex] }
			ids.splice(existedIndex, 1)
		}
		ids.unshift({ ...toAppend, lastViewedAt: Date.now() })
		commit('SET', { recentProductsIds: ids.slice(0, 200) })
	},
	getCategoryProducts(
		{ commit, state: { categoryProducts } },
		payload: Query<ProductKeys>
	) {
		const idCategory = +`${payload.idCategory}`.split(',')[0]
		// FINDING EXISTING CATEGORY
		let categoryIndex = categoryProducts.findIndex(
			(category) => category.iCategoryId == idCategory
		)

		// PUSH EMPTY CATEGORY IF NOT EXISTED
		if (categoryIndex === -1) {
			commit(UNSHIFT, [
				'categoryProducts',
				{
					slug: payload.slug,
					filterFetched: false,
					iCategoryId: idCategory,
					filter: initialFilter(),
					products: createPaginaion({
						perPage: 25,
						isLoaded: false,
					}),
				} as CategoryProducts,
			])
			categoryIndex = 0
		}

		// PATH OF STATE
		const currentCategory = categoryProducts[categoryIndex]
		const path = `categoryProducts.${categoryIndex}.products`
		const filterPath = `categoryProducts.${categoryIndex}.filter`
		const currentCategoryProducts = currentCategory.products
		type ProductsType = typeof currentCategoryProducts

		// SET LOADERS
		commit(SET, { currentCategoryId: idCategory })
		if (!currentCategoryProducts.data.length) {
			commit(SET, { [`${path}.isLoading`]: true })
		}
		// isRefetching
		else commit(SET, { [`${path}.isRefetching`]: true })

		// API CALL
		if (!currentCategory.filterFetched) {
			// !payload.iFiltersDataIds
			handleAction(
				filtersDataApi.index({
					pointer: 'filter',
					idCategory: idCategory,
					// orderByColumn: 'iFiltersDataId',
					// orderByValue: 'desc',
				} as Query),
				(res: any) => {
					let filters = omit(res, 'message', 'statusText', 'statusCode')
					filters = Object.entries(filters).reduce(
						(acc, [key, value]) => ({
							...acc,
							[key]: value.sort(
								(a: any, b: any) => a.iFiltersDataId - b.iFiltersDataId
							),
						}),
						{}
					)
					// Object.values(filters).forEach((v: any) => {
					// 	console.log(v.map((a: any) => a.iFiltersDataId))
					// })
					commit(SET, { [filterPath]: filters })
					commit(SET, {
						[`categoryProducts.${categoryIndex}.filterFetched`]: true,
					})
					// initialFilter(res),
				}
			)
		}

		// await sleep(3000)

		return handleAction(ProductsApi.index(payload), (res: any) => {
			commit(SET, {
				[`${path}`]: (v: ProductsType) => ({
					...v,
					...res,
					isLoaded: true,
					isLoading: false,
					isRefetching: false,
				}),
			})
		})
	},
	getSupplierProducts(
		{ commit, state: { supplierProducts } },
		payload: Query<ProductKeys>
	) {
		// FINDING EXISTING CATEGORY
		let supplierIndex = supplierProducts.findIndex(
			(supplier) => supplier.vReference == payload.vReference
		)

		// PUSH EMPTY CATEGORY IF NOT EXISTED
		if (supplierIndex === -1) {
			commit(UNSHIFT, [
				'supplierProducts',
				{
					vReference: payload.vReference,
					products: createPaginaion({ perPage: 25 }),
				} as SupplierProducts,
			])
			supplierIndex = 0
		}

		// PATH OF STATE
		const path = `supplierProducts.${supplierIndex}.products`
		// const filterPath = `supplierProducts.${supplierIndex}.filter`
		const currentSupplierProducts = supplierProducts[supplierIndex].products
		type ProductsType = typeof currentSupplierProducts

		// SET LOADERS
		commit(SET, {
			[`${path}.isRefetching`]: true,
			currentOemReference: payload.vReference,
		})
		if (!currentSupplierProducts.data.length) {
			commit(SET, { [`${path}.isRefetching`]: true })
		}

		payload = { ...payload, pointer: 'oem_products' }

		return handleAction(ProductsApi.index(payload), (res: any) => {
			commit(SET, {
				[`${path}`]: (v: ProductsType) => ({
					...v,
					...res,
					isLoaded: true,
					isLoading: false,
					isRefetching: false,
				}),
			})
		})
	},
	getProductById({ commit }, { id, query }) {
		return handleAction(
			ProductsApi.show(id, query),
			(res: any) => commit(SET, { product: res.product }),
			() => commit(SET, { product: null })
		)
	},
	createProduct({ commit }, data: any) {
		console.log(data)
		return handleAction(ProductsApi.store(data), (res: any) => {
			commit(SET, { categories: res })
		})
	},
	updateProduct({ commit }, data: any) {
		return handleAction(
			ProductsApi.update(data),
			(res: any) => {
				commit(UPDATE, ['products.data', res, 'iProductId'])
			},
			(er: any) => {
				console.log(er)
			}
		)
	},
	deleteProductById({ commit }, id: number | string) {
		return handleAction(ProductsApi.delete(id), () => {
			commit(DELETE, ['products.data', id, 'iProductId'])
		})
	},
	resetState({ commit }) {
		commit(SET, initialState())
	},
}

export default {
	state,
	getters,
	actions,
	mutations,
	namespaced: true,
}
























































































import Vue from 'vue'
import api from '@/api'

import { VFormRef } from '@/types'
import { mapActions } from 'vuex'
import { getValidators } from '@/mixins/FormMixin'
import { MetaInfo } from 'vue-meta'
import { APP_META } from '@/consts'

export default Vue.extend({
	name: 'ChangePassword',
	metaInfo(): MetaInfo {
		return {
			...APP_META,
			title: `Change Password | ${APP_META.title}`,
		}
	},
	data: () => ({
		error: null as any,
		checkError: null as any,
		rules: getValidators('required', 'password', 'match', 'cantMatch'),
		isLoading: false,
		isFormValid: false,
		isVisiblePassword: false,
		isVisibleNewPassword: false,
		isVisiblePasswordConfirmation: false,
		tokenRes: null as any,
		user: {
			password: '',
			password_confirmation: '',
		},
	}),
	computed: {
		token(): string {
			return (this.$route.query.token as string) || ''
		},
		rulesMatchPassword() {
			return (v: any) => {
				return this.rules.match(v, this.user.password, 'New Password')
			}
		},
	},
	watch: {
		token: {
			immediate: true,
			async handler(token: string) {
				if (!token) return
				const [err, res] = await api.get('/password/check', { token })
				if (err) {
					this.checkError = err
					// this.$toast.error('Invalid password reset token')
					// this.setAuthState({
					// 	authentication: {
					// 		dialog: false,
					// 		persistent: false,
					// 		currentTab: null,
					// 	},
					// })
					// return this.$router.push('/')
				}
				this.tokenRes = res
			},
		},
	},
	methods: {
		...mapActions('auth', ['setAuthState']),
		setCurrentTab(tab: string) {
			this.setAuthState({
				'authentication.currentTab': tab,
			})
		},
		async onChangePassword() {
			const formRef = this.$refs.formRef as VFormRef
			if (formRef && !formRef.validate()) return

			this.isLoading = true
			let [err] = await api.post('/password/reset', {
				email: this.tokenRes.email,
				token: this.tokenRes.token,
				...this.user,
			})
			if (err) {
				this.error = !!err
				return (this.isLoading = false)
			}

			this.error = !!err
			this.isLoading = false

			this.$router.push('/')
			this.$toast.success(
				`Password changed successfully. Please login with your new password.`
			)

			this.setAuthState({
				'authentication.currentTab': 'signin',
			})
		},
	},
})

// import { Country } from './../../types'
// import { Pagination } from 'vuelpers/lib/vuex'
import { countryApi } from '@/api'
import { ActionTree } from 'vuex'
import { createPaginaion } from 'vuelpers'
import { CountryState } from '@/types'

import {
	// MUTATION TYPES
	SET,
	UPDATE,
	DELETE,
	PUSH,
	createGetters,
	createMutations,
	handleAction,
} from 'vuelpers'

const initialState = (): CountryState => ({
	countries: createPaginaion(),
	allCountries: createPaginaion(),
	country: undefined,
})

const state = initialState()
const mutations = createMutations(SET, UPDATE, DELETE, PUSH)
const getters = createGetters<CountryState>(
	'country',
	'countries',
	'allCountries'
)

const actions: ActionTree<CountryState, unknown> = {
	getCountries({ commit }, payload: any) {
		commit(SET, { 'counties.isRefetching': true })
		return handleAction(countryApi.index(payload), (res: any) => {
			commit(SET, {
				countries: {
					data: res.countries,
					isRefetching: false,
				},
			})
		})
	},
	getAllCountries({ commit }, payload: any) {
		return handleAction(countryApi.index(payload), (res: any) => {
			commit(SET, { allCountries: res })
		})
	},
	getCountryById({ commit }, id: number | string) {
		return handleAction(countryApi.show(id), (res: any) => {
			commit(SET, { country: res })
		})
	},
	createCountry({ commit }, data: any) {
		return handleAction(countryApi.store(data), (res: any) => {
			commit(PUSH, ['countries.data', res])
		})
	},
	updateCountry({ commit }, data: any) {
		return handleAction(
			countryApi.update(data),
			(res: any) => {
				commit(UPDATE, ['countries.data', res, 'iCountryId'])
			},
			(er: any) => {
				console.log(er)
			}
		)
	},
	deleteCountryById({ commit }, id: number | string) {
		return handleAction(countryApi.delete(id), () => {
			commit(DELETE, ['countries.data', id, 'iCountryId'])
		})
	},
}

export default {
	state,
	getters,
	actions,
	mutations,
	namespaced: true,
}

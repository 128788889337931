var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"bottom":"","left":"","transition":"slide-x-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","icon":"","color":"#EE914C"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',{attrs:{"nav":"","dense":""}},[_vm._l((_vm.actions),function(action,i){return [(action.divider)?_c('v-divider',{key:'divider' + i,staticClass:"tw-my-2"}):_c('v-list-item',{key:i,attrs:{"color":"primary"},on:{"click":function($event){action.event && _vm.$emit(action.event, _vm.item)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"#EE914C"},domProps:{"textContent":_vm._s(action.icon)}})],1),_c('v-list-item-content',{staticClass:"tw-pr-8"},[_c('v-list-item-title',{staticClass:"tw-text-AAB tw-text-sm tw-font-normal",domProps:{"textContent":_vm._s(
							_vm.isFunction(action.text)
								? action.text(_vm.item)
								: action.text
						)}})],1)],1)]})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{ref:"btnRef",staticClass:"tw-relative tw-border-solid tw-px-10 tw-border-2 tw-border-accent tw-font-mons tw-flex tw-items-center tw-justify-center tw-whitespace-nowrap",class:[
		_vm.small ? 'tw-text-xl' : 'tw-text-2xl',
		_vm.pill ? 'tw-rounded-full' : 'tw-rounded-lg',
		_vm.dark ? 'tw-bg-primary ' : _vm.accent ? 'tw-bg-accent ' : 'tw-bg-white ',
		_vm.bold ? 'tw-font-bold' : 'tw-font-normal',
		_vm.dark || _vm.accent ? 'tw-text-white' : 'tw-text-primary',
		{
			'tw-select-none tw-pointer-events-none tw-opacity-50': _vm.disabled,
		} ],style:({ height: (_vm.height + "px") }),attrs:{"disabled":_vm.disabled},on:{"click":function($event){return _vm.$emit('click', $event)}}},[(_vm.loading)?_c('div',{staticClass:"tw-grid tw-absolute tw-inset-0 tw-place-items-center tw-rounded-full"},[_c('v-progress-circular',{attrs:{"size":"24","indeterminate":"","color":_vm.dark || _vm.accent ? 'white' : 'accent'}})],1):_vm._e(),_c('span',{class:{ 'tw-invisible': _vm.loading }},[_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }
import { Query, ProductType } from './../../types'
import { productTypeApi } from '@/api'
import { ActionTree } from 'vuex'
import { createPaginaion } from 'vuelpers'

import {
	// MUTATION TYPES
	SET,
	UPDATE,
	DELETE,
	createGetters,
	createMutations,
	handleAction,
} from 'vuelpers'
import { selectWithOld } from '@/helpers'

const initialState = () => ({
	productType: undefined,
	productTypes: createPaginaion(),
})

type State = Partial<ReturnType<typeof initialState>>

const state = initialState()
const mutations = createMutations(SET, UPDATE, DELETE)
const getters = createGetters<State>('productType', 'productTypes')

const actions: ActionTree<State, unknown> = {
	getProductTypes({ commit }, payload: Query = {}) {
		payload = {
			...payload,
			withColumns: selectWithOld<ProductType>('category', ['*']),
		}
		return handleAction(productTypeApi.show(payload), (res: any) => {
			commit(SET, { productTypes: res })
		})
	},
	getProductTypeById({ commit }, id: number | string) {
		return handleAction(productTypeApi.index(id), (res: any) => {
			commit(SET, { productType: res })
		})
	},
	createProductType({ commit }, data: any) {
		console.log(data)
		return handleAction(productTypeApi.store(data), (res: any) => {
			commit(SET, { productTypes: res })
		})
	},
	updateProductType({ commit }, data: any) {
		return handleAction(
			productTypeApi.update(data),
			(res: any) => {
				commit(UPDATE, ['productTypes.data', res, 'iProductTypeId'])
			},
			(er: any) => {
				console.log(er)
			}
		)
	},
	deleteProductTypeById({ commit }, id: number | string) {
		return handleAction(productTypeApi.delete(id), () => {
			commit(DELETE, ['productTypes.data', id, 'iProductTypeId'])
		})
	},
}

export default {
	state,
	getters,
	actions,
	mutations,
	namespaced: true,
}

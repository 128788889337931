import { omit } from 'lodash'
import { ActionTree } from 'vuex'
import { OrdersApi } from '@/api'
import { Order, OrderView, Query, RootState } from '@/types'

import {
	// MUTATION TYPES
	SET,
	UNSHIFT,
	MERGE,
	UPDATE,
	DELETE,
	//
	Pagination,
	handleAction,
	createGetters,
	createMutations,
	createPaginaion,
} from 'vuelpers'

export interface OrdersState {
	orderView: OrderView
	orders: Pagination<Order>
}

const initialState = (): OrdersState => ({
	orders: createPaginaion(),
	orderView: {
		dialog: false,
		data: undefined,
	},
})

const state = initialState()
const mutations = createMutations(SET, UNSHIFT, UPDATE, MERGE, DELETE)
const getters = createGetters<OrdersState>('orders', 'orderView')

const actions: ActionTree<OrdersState, RootState> = {
	changeStatus({ commit }, payload: Order) {
		return handleAction(
			OrdersApi.updateStatus({
				e_status: payload.eStatus,
				i_sales_order_id: payload.iSalesOrderId,
			}),
			() => {
				commit(UPDATE, ['orders.data', payload, 'iSalesOrderId'])
			},
			(err: any) => {
				console.log('changeStatus:ERR', err)
			}
		)
	},
	fetchOrders({ commit, state: { orders } }, payload: Query) {
		// LOADERS
		if (!orders.isLoaded) commit(SET, { 'orders.isLoading': true })
		else commit(SET, { 'orders.isRefetching': true })

		return handleAction(OrdersApi.index(payload), (res: any) => {
			commit(SET, {
				orders: (v: typeof orders) => ({
					...v,
					...omit(res, 'data'),
					isLoaded: true,
					isLoading: false,
					isRefetching: false,
				}),
			})
			commit(MERGE, ['orders.data', res.data, 'iSalesOrderId', 'push'])
		})
	},
	resetState({ commit }) {
		commit(SET, initialState())
	},
}

const ordersModule = {
	state,
	getters,
	actions,
	mutations,
	namespaced: true,
}

export type OrdersModule = typeof ordersModule

export default ordersModule

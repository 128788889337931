import { omit } from 'lodash'
import { ActionTree } from 'vuex'
import { manufacturererApi } from '@/api'
import { ManufacturerState, Query } from '@/types'
import {
	// MUTATION TYPES
	SET,
	PUSH,
	UPDATE,
	DELETE,
	MERGE,
	// HELPERS
	createGetters,
	createMutations,
	handleAction,
	createPaginaion,
	UNSHIFT,
} from 'vuelpers'

const initialState = (): ManufacturerState => ({
	manufacturer: undefined,
	manufacturers: createPaginaion(),
	allManufacturers: createPaginaion(),
	manufacturerGroups: {},
})
const state = initialState()
const mutations = createMutations(SET, UPDATE, UNSHIFT, DELETE, PUSH, MERGE)
const getters = createGetters<ManufacturerState>(
	'manufacturer',
	'manufacturers',
	'allManufacturers',
	'manufacturerGroups'
)
const actions: ActionTree<ManufacturerState, unknown> = {
	getManufacturerGroups({ commit }) {
		return handleAction(manufacturererApi.groupCounts(), (res: any) => {
			commit(SET, { manufacturerGroups: res.counts })
		})
	},
	getManufacturers({ commit, state: { manufacturers } }, payload: any) {
		commit(SET, { 'manufacturers.isRefetching': true })
		if (!manufacturers.data.length) {
			commit(SET, { 'manufacturers.isLoading': true })
		}
		return handleAction(manufacturererApi.index(payload), (res: any) => {
			commit(SET, {
				manufacturers: (v: typeof manufacturers) => ({
					...v,
					...res,
					isLoaded: true,
					isLoading: false,
					isRefetching: false,
				}),
			})
		})
	},
	getManufacturers2({ commit, state: { manufacturers } }, payload: Query) {
		commit(SET, { 'manufacturers.isRefetching': true })
		if (!manufacturers.data.length) {
			commit(SET, { 'manufacturers.isLoading': true })
		}
		const { replace = false, ...query } = payload
		return handleAction(manufacturererApi.index(query), (res: any) => {
			commit(SET, {
				manufacturers: (v: typeof manufacturers) => ({
					...v,
					...(replace ? res : omit(res, 'data')),
					isLoaded: true,
					isLoading: false,
					isRefetching: false,
				}),
			})
			!replace &&
				commit(MERGE, [
					'manufacturers.data',
					res.data,
					'iOemListId',
					'push',
				])
		})
	},
	getManufacturerById({ commit }, id: number | string) {
		return handleAction(manufacturererApi.show(id), (res: any) => {
			commit(SET, { category: res })
		})
	},
	createManufacturer({ commit }, data: any) {
		return handleAction(manufacturererApi.store(data), (res: any) => {
			commit(UNSHIFT, ['manufacturers.data', res])
		})
	},
	updateManufacturer({ commit }, data: any) {
		return handleAction(
			manufacturererApi.update(data),
			(res: any) => {
				commit(UPDATE, ['manufacturers.data', res, 'iOemListId'])
			},
			(er: any) => {
				console.log(er)
			}
		)
	},
	deleteManufacturerById({ commit }, id: number | string) {
		return handleAction(manufacturererApi.delete(id), () => {
			commit(DELETE, ['manufacturers.data', id, 'iOemListId'])
		})
	},
	getAllManufacturers({ commit, state: { allManufacturers } }, payload: any) {
		commit(SET, { 'allManufacturers.isRefetching': true })
		if (!allManufacturers.data.length) {
			commit(SET, { 'allManufacturers.isLoading': true })
		}

		return handleAction(manufacturererApi.index(payload), (res: any) => {
			commit(SET, {
				allManufacturers: (v: typeof allManufacturers) => ({
					...v,
					...res,
					isLoaded: true,
					isLoading: false,
					isRefetching: false,
				}),
			})
		})
	},
}
export default {
	state,
	getters,
	actions,
	mutations,
	namespaced: true,
}















import Vue from 'vue'
export default Vue.extend({
	name: 'BasePerPage',
	props: {
		total: {
			default: -1,
			type: Number as Vue.PropType<number>,
		},
		value: {
			default: 10,
			type: [String, Number] as Vue.PropType<string | number>,
		},
	},
	computed: {
		items() {
			return [
				{ text: '10', value: 10 },
				{ text: '25', value: 25 },
				{ text: '50', value: 50 },
				{ text: '100', value: 100 },
			]
		},
		vModel: {
			get(): string | number {
				return this.value
			},
			set(v: number) {
				this.$emit('input', v)
			},
		},
	},
})

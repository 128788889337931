




































































































import Vue from 'vue'
import api from '@/api'

import { VFormRef } from '@/types'
import { mapActions } from 'vuex'
import { getValidators } from '@/mixins/FormMixin'
import { APP_META } from '@/consts'
import { MetaInfo } from 'vue-meta'

export default Vue.extend({
	name: 'ForgotPassword',
	metaInfo(): MetaInfo {
		return {
			...APP_META,
			title: `Password Recovery | ${APP_META.title}`,
		}
	},
	data: () => ({
		error: false,
		emailSent: false,
		isLoading: false,
		resend: {
			count: 0,
			intv: null as any,
		},
		form: {
			valid: false,
		},
		credentials: {
			email: '',
		},
		rules: getValidators('required', 'email'),
	}),
	methods: {
		...mapActions('auth', ['setAuthState']),
		setCurrentTab(tab: string) {
			this.setAuthState({
				'authentication.currentTab': tab,
			})
		},
		async onSubmit() {
			const formRef = this.$refs.formRef as VFormRef
			if (formRef && !formRef.validate()) return

			this.isLoading = true
			let [err] = await api.post('/password/forgot', this.credentials)
			if (err) {
				this.error = !!err
				return (this.isLoading = false)
			}

			this.error = !!err
			this.isLoading = false
			this.emailSent = true

			this.resend = {
				count: 60,
				intv: setInterval(() => {
					this.resend.count -= 1
					if (this.resend.count === 0) {
						clearInterval(this.resend.intv)
						this.resend.intv = null
					}
				}, 1000),
			}
		},
	},
})

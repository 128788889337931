import { omit } from 'lodash'
import { ActionTree } from 'vuex'
import { CategoriesApi } from '@/api'
import { Category, CategoryState, Query } from '@/types'

import {
	// MUTATION TYPES
	SET,
	MERGE,
	DELETE,
	UPDATE,
	createGetters,
	createMutations,
	handleAction,
	createPaginaion,
	PUSH,
	UNSHIFT,
} from 'vuelpers'
import { selectWith } from '@/helpers'

const initialState = (): CategoryState => ({
	category: undefined,
	categories: createPaginaion(),
	allCategories: createPaginaion(),
})

const state = initialState()
const mutations = createMutations(SET, UPDATE, UNSHIFT, DELETE, MERGE, PUSH)
const getters = createGetters<CategoryState>('category', 'allCategories', {
	categories(state) {
		return {
			...state.categories,
			data: state.categories.data.sort((a, b) => {
				return a.iListOrder - b.iListOrder
			}),
		}
	},
})

const actions: ActionTree<CategoryState, unknown> = {
	async getCategories({ commit, state: { categories } }, payload: Query) {
		// LOADERS
		commit(SET, { 'categories.isRefetching': true })
		if (!categories.data.length) {
			commit(SET, { 'categories.isLoading': true })
		}

		return handleAction(CategoriesApi.index(payload), (res: any) => {
			commit(SET, {
				categories: (v: typeof categories) => ({
					...v,
					...omit(res, 'data'),
					isLoaded: true,
					isLoading: false,
					isRefetching: false,
				}),
			})
			commit(MERGE, ['categories.data', res.data, 'iCategoryId'])
		})
	},
	getAdminCategories({ commit, state: { categories } }, payload: Query) {
		// LOADERS
		commit(SET, { 'categories.isRefetching': true })
		if (!categories.data.length) {
			commit(SET, { 'categories.isLoading': true })
		}

		return handleAction(
			CategoriesApi.index({
				...payload,
				with: selectWith<Category>([{ categories: [] }]),
			}),
			(res: any) => {
				commit(SET, {
					categories: (v: typeof categories) => ({
						...v,
						...omit(res, 'data'),
						isLoaded: true,
						isLoading: false,
						isRefetching: false,
					}),
				})
				commit(SET, { 'categories.data': res.data })
			}
		)
	},
	getAllCategories({ commit }, payload: any) {
		return handleAction(CategoriesApi.index(payload), (res: any) => {
			commit(SET, { allCategories: res })
		})
	},
	getCategoryById({ commit }, { id, query }: any) {
		return handleAction(CategoriesApi.show(id, query), (res: any) => {
			commit(SET, { category: res })
		})
	},
	createCategory({ dispatch, state: { categories } }, data: any) {
		return handleAction(CategoriesApi.store(data), () => {
			dispatch('getAdminCategories', {
				perPage: categories.perPage,
				page: categories.currentPage,
			})
		})
	},
	updateCategory({ dispatch, state: { categories } }, data: any) {
		return handleAction(
			CategoriesApi.update(data, {
				with: selectWith<Category>([{ categories: [] }]),
			}),
			() => {
				dispatch('getAdminCategories', {
					perPage: categories.perPage,
					page: categories.currentPage,
				})
			},
			(er: any) => {
				console.log(er)
			}
		)
	},
	deleteCategoryById({ commit }, id: number | string) {
		return handleAction(CategoriesApi.delete(id), () => {
			commit(DELETE, ['categories.data', id, 'iCategoryId'])
		})
	},
}

export default {
	state,
	getters,
	actions,
	mutations,
	namespaced: true,
}













import Vue from 'vue'

import { Query } from '@/types'
import { mapActions } from 'vuex'

import FormAdvancedSearch from '../Forms/FormAdvancedSearch.vue'

export default Vue.extend({
	name: 'DialogFilter',
	components: {
		FormAdvancedSearch,
	},
	data: () => ({
		filter: {
			unit: '',
			reference: '',
		},
	}),
	computed: {
		vModel: {
			get() {
				return this.$store.state.global.filter.dialog
			},
			set(v) {
				this.$store.commit('SET', { 'filter.dialog': v })
			},
		},
	},
	methods: {
		...mapActions('products', ['getProducts']),
		async onAdvancedSearch(payload: Query) {
			let [err] = await this.getProducts(payload)
			if (!err) {
				this.vModel = false
			}
		},
	},
})

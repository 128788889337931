










































































import Vue from 'vue'

import { Product } from '@/types'
import { mapGetters } from 'vuex'
import { Pagination } from 'vuelpers'
import { Accessors } from 'vue/types/options'

export default Vue.extend({
	name: 'GlobalSearch',
	data: () => ({
		q: '',
		menu: false,
	}),
	mounted() {
		if (this.$route.query.q) {
			this.q = (this.$route.query.q || '') as string
			this.onSearchProducts()
		}
	},
	watch: {
		'$route.query': {
			deep: true,
			immediate: true,
			handler(query: any) {
				if (!query.q || this.q === query.q) return
				this.q = query.q
			},
		},
	},
	computed: {
		...(mapGetters('products', ['$searchProducts']) as Accessors<{
			$searchProducts: Pagination<Product>
		}>),
		qModel: {
			get(): string {
				return this.q
			},
			set(v: string): void {
				this.q = v
			},
		},
	},
	methods: {
		onClearSearchProducts() {
			this.q = ''
			this.onSearchProducts()
		},
		onSearchProducts() {
			if (this.$route.query.q === this.q) return
			this.menu = false

			this.$store.commit('products/SET', {
				'products.isLoaded': false,
			})

			const nextPath = this.q
				? `/products?type=search&q=${this.q}`
				: `/products?type=products`

			if (this.$route.path !== '/products') this.$router.push(nextPath)
			else this.$router.push(nextPath)
		},
		goNext(nextPath: string) {
			if (this.$route.path === nextPath) return
			this.$router.push(nextPath)
		},
		getText(item: any): string {
			if (typeof item === 'string') return item
			return item.vDescription || item.vCategory || item.vName
		},
	},
})

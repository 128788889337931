const useDraggableElement = (el: any) => {
	if (!el) return

	let clientX = 0,
		dClientX = 0
	let clientY = 0,
		dClientY = 0

	const getClientX = (e: any): number => {
		if (!e.touches) return e.clientX
		return e.touches[0].clientX
	}

	const getClientY = (e: any): number => {
		if (!e.touches) return e.clientY
		return e.touches[0].clientY
	}

	const onUnbind = () => {
		el.style.transition = 'all 300ms ease'
		const rect = el.getBoundingClientRect()

		// Outside top
		if (rect.top < 0) el.style.top = el.offsetTop - rect.top + 'px'

		// Outside bottom
		if (rect.top + 32 > window.innerHeight) {
			el.style.top = el.offsetTop - rect.top + window.innerHeight - 36 + 'px'
		}

		// Outside left
		if (rect.left + rect.width - 32 < 0) {
			el.style.left = el.offsetLeft - rect.left - rect.width + 64 + 'px'
		}

		// Outside right
		if (window.innerWidth < rect.right - rect.width + 64) {
			el.style.left =
				el.offsetLeft - rect.left + window.innerWidth - 76 + 'px'
		}

		// Unbind the events
		document.onmousemove = null
		document.onmouseup = null

		document.removeEventListener('touchmove', onDrag)
		document.removeEventListener('touchend', onUnbind)

		// Reset the element's style
		setTimeout(() => {
			el.style.transition = 'none'
		}, 350)
	}

	const onMouseDown = (e: any) => {
		e = e || window.event
		e.preventDefault()

		// get the mouse cursor position at startup:
		clientX = getClientX(e)
		clientY = getClientY(e)

		document.onmousemove = onDrag
		document.onmouseup = onUnbind

		document.addEventListener('touchmove', onDrag, { passive: false })
		document.addEventListener('touchend', onUnbind)
	}

	const onDrag = (e: any) => {
		e = e || window.event
		e.preventDefault()

		// calculate the new cursor position:
		dClientX = clientX - getClientX(e)
		dClientY = clientY - getClientY(e)

		clientX = getClientX(e)
		clientY = getClientY(e)

		// set the element's new position:
		el.style.top = el.offsetTop - dClientY + 'px'
		el.style.left = el.offsetLeft - dClientX + 'px'
	}

	const headerEl = el.querySelector('#header')

	const evEl = headerEl || el

	evEl.onmousedown = onMouseDown
	evEl.addEventListener('touchstart', onMouseDown, { passive: false })
}

export { useDraggableElement }

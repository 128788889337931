









import Vue from 'vue'

import { configsWith } from '@/queries'

import AuthMixin from '@/mixins/AuthMixin.vue'
import DialogAuthentication from './components/dialogs/DialogAuthentication.vue'

export default Vue.extend({
	name: 'App',
	mixins: [AuthMixin],
	components: { DialogAuthentication },
	created() {
		this.$store.dispatch('fetchConfig', {
			with: configsWith,
		})
	},
})

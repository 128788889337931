import { Pagination } from 'vuelpers/lib/vuex'
import { CurrencyCodeSymbols } from './consts'
import { AuthModule } from './store/modules/auth.store'
import { CartsModule, CartsState } from './store/modules/carts.store'

export type Id = number | string
export type Bool = 1 | 0 | boolean

export enum Role {
	Admin = 'admin',
	Customer = 'customer',
	SuperAdmin = 'super_admin',
	GuestCustomer = 'guest_customer',
}

export enum Status {
	Active = 'Active',
	Inactive = 'Inactive',
	Pending = 'Pending',
}

export enum OrderStatus {
	Pending = 'Pending',
	Approved = 'Approved',
	Cancelled = 'Cancelled',
	Downloaded = 'Downloaded',
	Proforma = 'Proforma',
	Acknowledged = 'Acknowledged',
	POPrinted = 'PO Printed',
	Shipped = 'Shipped',
}

export type Sort = {
	orderByColumn?: string
	orderByValue?: SortOrder
}

export type VFormRef = Vue & {
	validate: () => boolean
	reset: () => void
	clear: () => void
}

export type AnyRecord = Record<string, unknown>
export type AnyObject = {
	[key: string]: unknown
}

export interface Attachment {
	file: File
	src: string
}

export type VRef = Vue | Element | (Vue | Element)[] | undefined

export interface OrderView {
	dialog: boolean
	data?: Order
}

export interface Report {
	iReportId: Id
	iCustomerId: Id
	reportableId: Id
	reportableType: string
	tDescription: string
	eStatus: Status
	vImage: string
}

export interface Enquiry {
	iEnquiryId: Id
	idTimkenAlias: Id
	vContactName: string
	vContactEmail: string
	vContactPhone: string
	vContactMessage: string
	bIsPriceQuote: Bool
	dRequestedQuantity: number
}

export interface Breadcrumb {
	to: string
	text: string
	exact: boolean
	disabled: boolean
}

interface BaseModelSnake {
	deleted_at?: string
	created_at?: string
	updated_at?: string
}

export interface OrderSummary {
	orderQty: number
	orderLen: number
	orderVol: number
	orderWeight: number
	orderTotalItem: number
}

export interface BaseModel extends BaseModelSnake {
	deletedAt?: string | Date
	createdAt: string | Date
	updatedAt: string | Date
}

export interface Coupon extends BaseModel {
	iCouponId: Id
	iCount: number
	cFreeDelivery: 'Y' | 'N'
	cActive: 'Y' | 'N'
	discountPercent: number
	discountAmount: number
	discountShipAmount: number
	dValidFrom: string | Date
	dValidTill: string | Date
	vCoupon: string
	vComment?: string
}

export interface ContactUs {
	iContactUsId: Id
	vFullName: string
	vEmail: string
	vSubject: string
	tComment: string
	vMobilePhone: string | number
	dtAddedDate: Date
}

export interface Faq {
	iFaqId: Id
	vQuestion: string
	tAnswer: string
	iFaqOrder: number
	dtAddedDate: Date
	dtUpdatedDate: Date
	eStatus: 'Active' | 'Inactive'
	eType: 'General' | 'Auction' | 'registration' | 'Orders'
}

export interface County extends BaseModel {
	iCountyId: number
	iCountryId: number | string
	vCounty: string
	eStatus: string | boolean
	vCountryIsdCode: string
	country?: Country[] | ''
}

export type BackendError = {
	[key: string]: string[]
}

export interface Permission {
	name: string
	slug: string
}

export interface User extends BaseModel {
	iUserId: Id
	vFirstName: string
	vLastName: string
	vRole: Role
	email: string
	eStatus: Status | ''
	vMobileNumber: string
	password: string
	passwordConfirmation?: string
	password_confirmation?: string
	permissions: Permission[]
	attachment?: string
	vIPAddress?: string
	vProfileImage?: string
	customer?: Customer
}

export interface SuperUser {
	user: User
	accessToken: string
	tokenExpiresAt: Date | string
}

export type GuestUser = {
	data: User
	token: string
} | null

export interface SalesOrder extends BaseModel {
	iSalesOrderId: Id
	customer: Customer
	salesOrders: SalesOrder[]
}

export interface PaypalPayment {
	id: Id
	payer: {
		status: string
		payment_method: string
	}
}

export interface DeliveryCharge extends BaseModel {
	iDeliveryChargeId: Id
	iProductId: Id
	iSource?: number
	vSupplier?: string
	bIsEnable: number
	vFree: string
	VOneSup: string
	vInfo: string
	vAddInfo: string
	vService: string
	dMinWeight: number
	dMaxWeight: number
	dMinQty: number
	dMaxQty: number
	dMinLen: number
	dMaxLen: number
	dMinVol: number
	dMaxVol: number
	dMinCharge: number
	dmcWtLimit: number
	dmcLenLimit: number
	dmcVolLimit: number
	dXtraWtCharge: number
	dXtraQtyCharge: number
	dXtraLenCharge: number
	dXtraVolCharge: number
	dRoundUpTo: number
	dMaxCharge: number
	dMinOrder: number
	vCountries?: string
	vExclCountries?: string
	vZip?: number
	vExclZip?: number
	dCharge: number
}

export interface OrderItem extends BaseModel {
	iOrderItemId: Id
	iSalesOrderId: Id
	iProductId: Id
	iSupplierListId: Id
	vTitle: string
	dPrice: number
	iQuantity: number
	dTotalPrice: number
	dWeight: number
	dTotalWeight: number
	vSku: string
	vSkuRaw: string
	vTaxCode: string
	dTaxRate: number
	vBrand: string
	vVariant: string
	vPrefix: string
	dTotalTaxAmount: number
	product: Product
}

export interface Order extends BaseModel {
	iSalesOrderId: Id
	iAddressId: Id
	iBillingCountryId: Id
	iBillingCountyId: Id
	iCustomerId: Id
	iShippingCountryId: Id
	iShippingCountyId: Id
	iDeliveryChargeId: Id
	iInternalOrder: number
	iInvoiceNumber: number
	iOrderNumber: number
	iProcessed: number
	iSupplierCount: number
	dCouponDiscountAmount: number
	dDeliveryPrice: number
	dDeliveryTax: number
	dItemTotal: number
	dSubTotal: number
	dTotalTax: number
	dTotalPrice: number
	dTotalWeight: number
	dtOrderDate: Date | string
	dtUpdateDate: Date | string
	eStatus: OrderStatus
	tComment: string
	tInstruction: string
	tInternalNotes?: string
	tSpecialInstruction: string
	vBillingAddress?: string
	vBillingAddress2?: string
	vBillingCity?: string
	vBillingCompanyName?: string
	vBillingCountryName?: string
	vBillingCountyName?: string
	vBillingEmail: string
	vBillingMobile?: string
	vBillingName?: string
	vBillingPhone: string
	vBillingZipcode?: string
	vCompanyName: string
	vCoupon: string
	vCurrencyExRate: string
	vCurrency: CurrencyCode
	vCurrencySign: string
	vDeliveryInstruction?: string
	vInstruction?: string
	vPaymentMethod: string
	vShippingAddress2?: string
	vShippingAddress?: string
	vShippingCity?: string
	vShippingCompanyName?: string
	vShippingCountryName?: string
	vShippingCountyName?: string
	vShippingEmail?: string
	vShippingMobile?: string
	vShippingName?: string
	vShippingPhone?: string
	vShippingZipcode?: string
	vReference?: string
	vTransactionId: Id
	orderItems: OrderItem[]
	customer: Customer
	deliveryCharge: DeliveryCharge
	couponDiscount: Coupon
}

export interface CurrentOrder {
	step: number
	orderRef?: SalesOrder
	completedStep: number
	billingAddress?: Address
	shippingAddress?: Address
	deliveryCharge?: DeliveryCharge
	differentShippingAddress: boolean
	deliveryNotes?: string
	vReference?: string
	paypalPaymentRef?: PaypalPayment
	coupon: Partial<Coupon>
}

export type YesNo = 'Yes' | 'No'

export interface Address<T = Customer> extends BaseModel {
	iAddressId: Id
	county?: County
	iCountyId: Id
	country?: Country
	iCountryId: Id
	eDefault: YesNo
	addressableId: Id
	addressableType: string
	addressable?: T
	vAddress?: string
	vAddress2?: string
	vCity?: string
	vZipCode?: string
	vPhone?: string
	vMobile?: string
	vEmail?: string
	vContactName?: string
	vCompanyName?: string
}

export type CurrencyCode = keyof typeof CurrencyCodeSymbols

export interface Currency {
	name: string
	flag: string
	rate: number
	code: CurrencyCode
}

type PaypalEnv = 'sandbox' | 'production'
type PaypalClient = {
	[key in PaypalEnv]: string
}
export type Paypal = {
	env: PaypalEnv
	client: PaypalClient
	value?: string
}

export interface Config {
	iConfigureId?: Id
	tMessage1: string
	tMessage2: string
	tMessage3: string
	tMessage4?: string
	vDefaultCurrencyCode: CurrencyCode
	dPercentage?: number
	vDefaultLanguage: string
	vDefaultTimezone: string
	vEmail: string
	vLogo: string
	vPhone: string
	vPhoneCode: string
	dMinimumOrderValue: number
	vExchangeCurrencyCodes: Currency[]
	paypal: string | Paypal
	trustedIpList: string[]
	requestIp: string
	vAddress: {
		address: string
	}
	apiInfo: {
		apiKey: string
		apiSecret: string
	}
}

export interface TimkenAlias {
	iTimkenAliasId: Id
	idProduct: Id
	vCode: string
	vCompnay: string
	vTimkenCode: string
	vDescription: string
	product?: Product
	manufacturer?: Manufacturer
}

export interface UpSell extends BaseModel {
	id: Id
	iProductId: Id
	iUpSellId: Id
	bIsRelatedProduct: Bool
	bIsSimilarProduct: Bool
	upSellProduct: Product
}

export interface ProductReview extends BaseModel {
	iProductReviewId: Id
	iCustomerId: Id
	iProductId: Id
	iRating: number
	vFeedback?: string
	eStatus: Status
	vImage?: string
	customer: Customer
}

export interface Delivery extends BaseModel {
	iDeliveryId: Id
	idProduct: Id
	vMessage: string
}

export interface Brand extends BaseModel {
	iBrandsId: Id
	dSellPrice: number
	iQuality: number
	idProduct: number
	vBrand: string
	vSkuRaw: string
	vSKURaw: string
}

export interface Alias {
	iAliasesId: Id
	idProduct: Id
	vCo: string
	vCompany: string
	vDetails: string
	vSku: string
	vSkuRaw: string
}

export interface Product extends BaseModel {
	idProduct: Id
	iProductId: Id
	vId: string
	iQuickFind: number
	vDescription: string
	vDescription2: string
	vLineDrawImg: string
	vImage: string
	vImageAlt: string
	vInfoPage: string
	iActive: Bool
	iSource: Id
	iViews: number
	vData10: string
	vSku: string
	vSKU: string
	vHighlight?: string
	dOurPrice: number | string
	dSellPrice: number | string
	idCategory: Id
	idProductTypes: Id
	iSCBCategory: number
	iPicardType: number
	iDelItemsId: Id
	iIsStocked: number
	iStockQty: number
	iBallBearing: number
	dOd: number
	dOdin: number
	dWidth: number
	dWeight: number
	dWidthin: number
	dLength: number
	dVolume: number
	vBrand: string
	vSkuRaw: string
	vSKURaw: string
	vVariant: string
	vAka: string
	iQuality: number
	category?: Category
	productType?: ProductType
	relatedProducts?: UpSell[]
	similarProducts?: UpSell[]
	timkenAliases?: TimkenAlias[]
	aliases: Alias[]

	iBrands: Id
	brands?: Brand[]
	productReviews?: ProductReview[]
	reviewableCustomers?: Customer[]

	iDeliveryId: Id
	deliveryMessage: Delivery
}

export interface Cart extends BaseModel {
	iCartId: Id

	idBrand: Id
	idProduct: Id
	iCustomerId: Id
	iQuantity: number

	brand: Brand
	product: Product
}

// VUEX
export interface AuthState {
	accessToken: string
	tokenExpiresAt: string
	currentUser: User | null
	superUser?: SuperUser
	authentication: {
		dialog: boolean
		persistent: boolean
		currentTab: string | null
	}
}
export interface CategoryState {
	categories: Pagination<Category>
	category?: Category
	allCategories: Pagination<Category>
}
export interface subCategoryState {
	categories: Pagination<Category>
	category?: Category
}
export interface productTypeState {
	productTypes: Pagination<ProductType>
	productType?: ProductType
}
export interface ManufacturerState {
	manufacturers: Pagination<Manufacturer>
	allManufacturers: Pagination<Manufacturer>

	manufacturer?: Manufacturer
	manufacturerGroups: { [key: string]: number }
}

export interface SupplierState {
	suppliers: Pagination<Supplier>
	supplier?: Supplier
}
export interface CustomerState {
	customers: Pagination<Customer>
	customer?: Customer
}
export interface CountryState {
	countries: Pagination<Country>
	country?: Country
	allCountries: Pagination<Country>
}
export interface CountyState {
	counties: Pagination<County>
	county?: County
	country?: Country[]
}

export type RootState = {
	carts: CartsState
	auth: AuthState
}
export interface VuexModules {
	auth: AuthModule
	carts: CartsModule
}

// END VUEX

export type ImgSize = 'tn' | 'full' | 'line' | 'medium' | 'none'

export interface Filter extends BaseModel {
	iFilterId: Id
	idCategory: Id
	iFiltersDataId: Id
	vSQL: string
	vData: string
	vFilter: string
	vCategory: string
	vSQLField: string
	vSqlField: string
	vSQLValue: string
}

export interface FilterObject {
	[key: string]: Filter[]
}

export interface CategoryProducts {
	iCategoryId: Id
	products: Pagination<Product>
	slug?: string
	filter?: FilterObject
	filterFetched?: boolean
}

export interface SupplierProducts {
	vReference: string
	products: Pagination<Product>
}

export interface ProductView {
	id: Id
	lastViewedAt: number
}

export interface ProductsState {
	products: Pagination<Product>
	searchProducts: Pagination<Product>
	topProducts: Pagination<Product>
	similarProducts: Pagination<Product>
	relatedProducts: Pagination<Product>
	newArrivedProducts: Pagination<Product>
	product?: Product
	// Recent
	recentProductsIds: ProductView[]
	recentProducts: Pagination<Product>
	// Supplier
	currentOemReference: string | null
	supplierProducts: SupplierProducts[]
	// Category
	currentCategoryId: Id | null
	categoryProducts: CategoryProducts[]
}
export interface ConfigurationState {
	configureSettings: Config | undefined
}

export interface Image {
	vImage: string
	vImageAlt?: string
	vAltImage?: string
}
export interface Category extends BaseModel, Image {
	iCategoryId: Id
	iFilterId: Id
	vCategory: string
	iActive: Bool
	iParent: number
	vItem: string
	vUpSell: string
	vImageLine: string
	vAltLine: string
	vKeyWords: string
	vProdType: string
	iListOrder: number
	iTotalItems: number | null
	vDataTable: string
	vSpecial: string
	vImg: string
	vImageAlt: string
	vKeywords: string
	vFilter1: string
	vFilter2: string
	vFilter3: string
	vFilter4: string
	vFilter5: string
	vFilter6: string
	vFilter7: string
	vFilter8: string
	vFilter: string
	vData: string
	subCategoryId: Id
	vSqlField: string
	vInfoPage: string
	filterDatas?: Filter[]
	categories: Category[]
}
export interface SubCategory extends BaseModel, Image {
	categoryId: number | undefined
	iActive: Bool
	iParent: number
	vItem: string
	vUpSell: string
	vImageLine: string
	vAltLine: string
	vKeyWords: string
	vProdType: string
	iListOrder: number | null
	iTotalItems: number | null
	vDataTable: string
	vSpecial: string
	vImg: string
	vImageAlt: string
	vKeywords: string
	vFilter1: string
	vFilter2: string
	vFilter3: string
	vFilter4: string
	vFilter5: string
	vFilter6: string
	vFilter7: string
	vFilter8: string
	iCategoryId: number | null
}

export interface ProductType extends BaseModel {
	iProductTypeId: number | null
	iCategoryId: number | null
	vProdType: string
	vItem: string
	vUpSell: string
	vImage: string | null
	vAltImage: string
	vImageLine: string
	vAltLine: string
	vKeyWords: string
	vSpecial: string
	iActive: string
	category: Category
}
export interface Country extends BaseModel {
	iCountryId?: number | null
	vCountry?: string
	vCountryCode: string
	iNumeric: string
	vCountryIsdCode: string
	iTaxCodeId: string
	eDefault?: string | null
	eStatus?: string | null
}

export interface Manufacturer extends BaseModel {
	iOemListId: number | null
	vReference: string
	vName: string
	vLogo: string
	vUrl: string
	iNo: number
	timkenAliases?: TimkenAlias[]
}
export interface Supplier extends BaseModel {
	iSupplierListId: number | null
	vReference: string
	vName: string
	vLogo: string
	vUrl: string
}

export interface Customer extends BaseModel {
	iCustomerId: Id
	iUserId: Id
	vCompany: string
	vAccountRef?: string
	vVatNo?: string
	eNewsLetter: YesNo
	eHearAbout?: 'Search Engine' | 'Newspaper' | 'Friend' | 'Customer'

	bIsTaxIncluded: boolean
	vVatNO?: string
	user: User
	addresses?: Address[]
}

export enum SortOrder {
	ASC = 'ASC',
	DESC = 'DESC',
}

export interface Query<T = string> {
	page?: number
	perPage?: number
	withColumns?: string
	selectColumns?: string
	with?: string
	orderByColumn?: T
	orderByValue?: SortOrder
	[key: string]: any
}

// TYPE KEYS
export type ProductKeys = keyof Product
export type CategoryKeys = keyof Category
export type CartKeys = keyof Cart

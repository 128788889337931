import Vue from 'vue'
import App from './App.vue'
import VueMeta from 'vue-meta'
import store from './store'
import VueToast from 'vue-toastification'
import Notifications from 'vue-notification'
import Helpers, { createHistoryStack } from './helpers'

import { createRouter } from './router'
import { syncAuthState } from './mixins/UseAuth'
import { registerComponents, TIME, VOnReveal } from 'vuelpers'

// PLUGINS
import i18n from './i18n'
import vuetify from './plugins/vuetify'

// STYLES
import './assets/css/fonts.css'
import './assets/css/tailwind.css'
import './assets/scss/main.scss'
import 'vue-toastification/dist/index.css'

// PWA
import './registerServiceWorker'

const main = async () => {
	const router = createRouter()

	// REGISTER BASE COMPONENTS
	registerComponents(
		require.context(
			'./components',
			true, // is deep
			/(Base|App)[A-Z]\w+\.(vue|js|ts)$/
		),
		Vue
	)

	// PRODUCTION TIPS
	Vue.config.productionTip = false

	// PROTOTYPE
	Vue.prototype.$m = require('moment')

	// SESSION
	syncAuthState()

	// PLUGINS
	Vue.use(Helpers)
	Vue.use(createHistoryStack(router))
	Vue.use(VOnReveal)
	Vue.use(Notifications)
	Vue.use(VueMeta, {
		refreshOnceOnNavigation: true,
	})
	Vue.use(VueToast, {
		position: 'bottom-right',
		timeout: TIME.getMS(10, 'SEC'),
	})

	// NEW VUE INSTANCE
	const app = new Vue({
		i18n,
		store,
		router,
		vuetify,
		render: (h) => h(App),
	})

	// MOUNTING APP
	app.$mount('#app')
}

main()

import store from '@/store'
import { useRouter } from '@/router'
import { SERVER_BASE_URL } from '@/consts'
import { getDefaultRoute } from '@/helpers'
import { convertKeysToSnakeCase, createAPI, FetchAPI } from 'vuelpers'

const CONFIG: any = {
	baseURL: `${SERVER_BASE_URL}/api`,
	response: {
		convertCase: 'camelCase',
	},
}

const api = createAPI(CONFIG)
const fetch = new FetchAPI(CONFIG)

api.instance.interceptors.request.use((config) => {
	return { ...config, params: convertKeysToSnakeCase(config.params) }
})

api.instance.interceptors.response.use(
	(v) => v,
	(err) => {
		if (err?.response?.status === 401) {
			if ((err?.response?.config?.url || '').includes('/profile')) {
				return store.dispatch('auth/logout')
			}
			const vRole = store.state.auth.currentUser?.vRole
			if (vRole) {
				const router = useRouter()
				router.push(getDefaultRoute(vRole)).catch(() => {
					// TODO: handle error
				})
			}
		}
	}
)

export * from './auth.api'

export { fetch }

export default api

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$isLoggedIn)?_c('v-menu',{attrs:{"left":"","max-width":"600","nudge-bottom":"40","close-on-content-click":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.$currentUser)?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-avatar',{attrs:{"color":"accent","size":_vm.$vuetify.breakpoint.mdAndDown ? 32 : 48}},[(!_vm.$currentUser.vProfileImage)?_c('span',{staticClass:"tw-text-white tw-font-medium tw-font-ave"},[_vm._v(_vm._s(_vm.$currentUser.vFirstName ? _vm.$currentUser.vFirstName.charAt(0).toUpperCase() : 'U'))]):_c('v-img',{ref:"profileImageRef",staticClass:"tw-border-2 tw-border-solid tw-border-accent",attrs:{"src":_vm.$helper.imgSrc(_vm.$currentUser.vProfileImage, {
							type: 'users',
						})},on:{"error":function($event){return _vm.$helper.vImgErr({
							ref: _vm.$refs.profileImageRef,
						})}}})],1)],1):_vm._e()]}}],null,false,1564034016)},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$helper.fullName(_vm.$currentUser))+" ")]),_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.$currentUser.email)+" ")]),_c('v-divider'),_c('v-list',[_c('v-list-item',{attrs:{"to":"/profile"}},[_vm._v("Profile")]),(!_vm.$superUser)?_c('v-list-item',{on:{"click":function($event){return _vm.$emit('logout')}}},[_vm._v("Logout")]):_vm._e()],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }